.section.section--fruit-facts-index {
    --pt-section: 45px;
    --pb-section: 130px;

    .fruity-facts__boxes {
        margin-top: 40px;
    }

    @include media-breakpoint-up(md) {
        --pt-section: 80px;
        --pb-section: 140px;
    }

    @include media-breakpoint-up(lg) {
        --pt-section: 70px;
        --pb-section: 140px;
    }

    @include media-breakpoint-up(xl) {
        --pb-section: 160px;
    }

    @include media-breakpoint-up(xxl) {
        --pt-section: 45px;
        --pb-section: 230px;
    }

    @include media-breakpoint-up(fhd) {
        --pb-section: 160px;

        .fruity-facts__boxes {
            justify-content: unset;
        }
    }
}
