.wave {
    @include fill-w-h;
    width: calc(4 * var(--width-wave));
    height: var(--height-wave);
    top: 1px;
    transform: translate(0, -100%);
    animation: wave 8s cubic-bezier(.36, .45, .63, .53) infinite;

    path {
        transition: fill var(--speed-default) ease-in-out;
    }

    html.contrast-mode & {
        display: none;
    }

    @keyframes wave {
        0% {
            transform: translate(0, -100%);
        }

        100% {
            transform: translate(calc(-1 * var(--width-wave)), -100%);
        }
    }
}
