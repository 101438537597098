.hero.hero--about {
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: center;

    .hero__title {
        margin-bottom: 20px;

        span {
            display: inline-flex;
        }

        img {
            width: 170px;
            height: 70.6px;

            @include media-breakpoint-down(sm) {
                width: 110px;
                height: 45.7px;
            }
        }
    }

    .hero__image {
        display: block;
        max-width: 200px;
        margin: 0 auto;
        position: relative;

        img {
            @include fill-w-h;
            object-fit: contain;
        }

        &.hero__image--background {
            display: none;

            .leaning-bear-st0 {
                html.contrast-mode-high & {
                    fill: color(cola) !important; /* stylelint-disable-line declaration-no-important */
                }
            }
        }
    }

    .hero__description {
        &.hero__description--sm {
            display: flex;
        }

        &.hero__description--md {
            display: none;
        }
    }

    .hero--about__usp {
        margin-top: 20px;
        font-size: 1.5rem;
        line-height: 2.5rem;

        .hero--about__usp-item:nth-child(2) .hero--about__usp__image {
            height: 130px;
            margin-top: 30px;
        }
    }

    .video-wrapper {
        margin-top: 30px;
    }

    .hero--about__usp__title {
        margin-top: 10px;
    }

    .hero--about__usp__image {
        height: 160px;
        position: relative;

        img {
            @include fill-w-h;
            object-fit: contain;
        }
    }

    @keyframes rotate-up-down {
        0% { transform: rotate(0turn); }
        25% { transform: rotate(.01turn) translateX(-8px); }

        75% { transform: rotate(-.01turn) translateX(5px); }
        100% { transform: rotate(0turn); }
    }

    @include media-breakpoint-up(md) {
        font-size: 1.6rem;
        line-height: 2.8rem;

        .hero__image {
            max-height: 161px;

            &.hero__image--large {
                max-width: unset;
                height: 560px;
                max-height: unset;
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }

        .hero__title--spacing-top-md {
            margin-top: 15px;
        }

        .hero__description {
            margin-top: 10px;
            padding: 0 40px;

            &.hero__description--sm {
                display: none;
            }

            &.hero__description--md {
                display: block;
                margin-top: 0;
            }
        }

        .hero--about__usp {
            margin-top: 40px;
            font-size: 1.5rem;
            line-height: 2.5rem;
        }

        .hero--about__usp__title {
            margin-top: 20px;
            margin-bottom: 34px;
        }
    }

    @include media-breakpoint-up(xl) {
        font-size: 2rem;
        line-height: 3.4rem;

        .hero__title {
            img {
                width: 170px;
            }
        }

        .hero__image {
            &.hero__image--background {
                display: block;
                max-width: unset;
                height: 710px;
                max-height: unset;
                position: absolute;
                top: 200px;
                left: -1px;

                path#hoofd {
                    transform-origin: 30% 40%;
                    animation: rotate-up-down;
                    animation-duration: 3s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: infinite;
                    animation-fill-mode: forwards;
                }
            }
        }

        .hero--about__usp {
            font-size: 2rem;
            line-height: 3.4rem;
        }

        .video-wrapper {
            margin-top: 60px;
        }
    }
}
