.navbar__search {
    position: relative;

    .navbar__search-view {
        display: flex;
    }

    .navbar__search-view__cancel {
        &::before,
        &::after {
            @extend %icon;
        }

        @media (hover: hover) and (pointer: fine) {
            @include center-y('right', 20px);
            width: 25px;
            height: 25px;

            &::before,
            &::after {
                @include contrast(false, false, color, false);
                display: block;
                content: $icon-minus;
                font-size: 5px;
                color: color(metallic-bronze);
                position: absolute;
                top: 50%;
                left: 50%;
            }

            &::before {
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }

    .form-group {
        margin-bottom: 0;
        position: relative;

        i {
            @include center-y('left', 15px);
            font-size: 16px;
        }

        .form-control {
            border: 1px solid color(alto);
            padding: {
                right: var(--px-form-control);
                left: 40px;
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button {
                display: none;
            }
        }

        @include media-breakpoint-up(xxl) {
            i {
                font-size: 18px;
                left: 20px;
            }

            .form-control {
                padding: {
                    right: calc(var(--px-form-control) * 3);
                    left: 50px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 30px;
    }

    @media (hover: hover) and (pointer: fine) {
        @include media-breakpoint-up(lg) {
            opacity: 0;
            width: 100%;
            margin-top: calc((var(--size-input) / 2) * -1);
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateX(40px);
            z-index: 4;
            transition: opacity var(--speed-default) linear, transform var(--speed-default) var(--cubic-bezier-2);

            .search-collapsed & {
                opacity: 1;
                pointer-events: all;
                transform: translateX(0);
                transition: opacity var(--speed-default) linear, transform var(--speed-default) var(--cubic-bezier-2);
            }
        }
    }
}

.navbar__settings,
.navbar__toggler-search {
    @include media-breakpoint-up(xl) {
        display: block;
        line-height: 1;

        i {
            @include contrast(false, false, color, false);
            font-size: 30px;
            color: color(milk);

            .nav-scrolling & {
                color: color(metallic-bronze);
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        i {
            font-size: 36px;
        }
    }
}

.navbar__settings {
    @include media-breakpoint-up(xl) {
        display: none;

        html[lang='en_US'] & {
            display: block;
        }
    }
}
