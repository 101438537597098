.read-more--container {
    .read-more {
        @include animation-content(var(--speed-default));
        display: none;
    }

    &.show-more-visible {
        .read-more {
            display: block;
        }

        &.toggle-more-btn--hide-on-active {
            display: none;
        }
    }
}

.toggle-more-btn {
    display: inline-block;

    .show-more-visible & {
        &.toggle-more-btn--hide-on-active {
            display: none;
        }
    }
}