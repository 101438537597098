.modal {
    .webshops {
        margin-top: 30px;

        .webshop-item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;

            img {
                max-width: 80%;
            }
        }

        .content-loader {
            position: static;
            transform: translate(0, 0);
        }

        &.webshops--list {
            margin-top: 50px;

            &.hide {
                display: none;
            }
        }
    }
}
