.card-collection-overview {
    text-align: center;

    .card-collection-overview__airplane {
        max-width: 170px;
        position: absolute;
        top: -55px;
        right: -30px;
    }

    .card-collection-overview__title {
        margin-bottom: 10px;
        padding: 0 5px;
    }
}
