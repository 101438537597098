.custom-select-wrapper {
    position: relative;

    &:not(.custom-select-wrapper--collapse) {
        height: var(--size-input);
    }
}

.custom-select {
    width: 100%;
    border: 0;
    border-radius: 5px;
    background-color: color(milk);
    font-family: $font-family-secondary;
    color: color(metallic-bronze);
    filter: drop-shadow(0 0 0 rgba(color(cola), .1));
    position: absolute;

    select {
        display: none;
    }

    .select-selected {
        display: flex;
        align-items: center;
        height: var(--size-input);
        border-radius: 5px;
        padding: 5px var(--px-form-control);
        font-weight: 500;
        font-size: 2rem;
        color: inherit;
        white-space: nowrap;
        cursor: pointer;

        &::after {
            @extend %icon;
            margin-left: auto;
            content: $icon-chevron-down;
            font-size: 14px;
            line-height: 1;
            pointer-events: none;
            transition: transform var(--speed-default) ease-out;
        }

        @media (hover: hover) and (pointer: fine) {
            &::after {
                transition: transform var(--speed-default) var(--cubic-bezier-2);
            }

            &:hover {
                &::after {
                    transform: scale(1.1);
                }
            }
        }
    }

    .select-items.collapsing {
        .select-items__body {
            overflow: hidden;
        }
    }

    .select-items__body {
        @include overflow(y);
        max-height: calc(var(--size-input) * 5);
        border: 0;
        border-bottom-right-radius: calc(var(--size-input) / 2);
        border-bottom-left-radius: calc(var(--size-input) / 2);
        position: relative;
        z-index: 10;

        .select-items__body__option {
            opacity: 0;
            display: flex;
            align-items: center;
            min-height: var(--size-input);
            margin: 0 var(--px-form-control);
            border-top: 1px solid rgba(color(metallic-bronze), .3);
            font-weight: 400;
            font-size: 1.7rem;
            cursor: pointer;
            position: relative;
            transform: translateY(20px);
            transition: transform var(--speed-slow) var(--cubic-bezier-2), opacity var(--speed-default) linear, color var(--speed-default) ease-in-out;

            &.same-as-selected {
                display: none;
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
                    transform: translate3d(0, 0, 0) scale(1.01);
                }
            }
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                .select-items__body__option {
                    color: rgba(color(metallic-bronze), .4);
                }
            }
        }
    }

    .select-removal {
        margin-top: 0;
        position: absolute;
        top: calc(.5 * var(--size-input));
        right: 50px;
        transform: translateY(-50%);
    }

    &.custom-select--open,
    &.custom-select--active {
        filter: drop-shadow(0 5px 5px rgba(color(cola), .1));
        z-index: 10;

        .select-selected {
            &::after {
                transform: scaleY(-1);
            }
        }

        .select-items__body__option {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.custom-select--outline {
        border: 2px solid color(milk);
        background: transparent;

        .select-selected {
            color: color(milk);
        }

        &.custom-select--open,
        &.custom-select--active {
            background: color(milk);

            .select-selected {
                color: inherit;
            }
        }
    }

    &.disabled {
        opacity: .6;
        pointer-events: none;
    }

    .custom-select-wrapper--collapse & {
        position: relative;
    }

    @media (hover: hover) and (pointer: fine) {
        transition: filter var(--speed-default) ease-out;
    }
}
