.share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
        display: flex;
        margin: 0 -4px;

        li {
            padding-right: 4px;
            padding-left: 4px;
        }

        a {
            margin-top: 0;

            i {
                font-size: 17px;
                z-index: 1;

                &.icon-envelope {
                    font-size: 13px;
                }
            }
        }
    }

    .share__label {
        font-family: $font-family-secondary;
        font-weight: 500;

        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 25px;
        }
    }
}