.navbar__language-switcher {
    --size-language: 45px;
    --size-language-border: 8px;
    display: flex;
    justify-content: center;

    .switcher {
        position: relative;
    }

    .language {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .language__figure {
            display: block;
            position: relative;

            &::before {
                display: block;
                width: var(--size-language);
                height: var(--size-language);
                border-radius: 50%;
                background-color: color(metallic-bronze);
                clip-path: url('#language-background-clip');
                content: '';
            }

            img {
                width: calc(var(--size-language) - var(--size-language-border));
                height: calc(var(--size-language) - var(--size-language-border));
                object-fit: cover;
                clip-path: url('#language-clip');
                position: absolute;
                top: calc(var(--size-language-border) / 2);
                left: calc(var(--size-language-border) / 2);
            }

            span {
                display: none;
            }
        }

        .language__label {
            display: block;
            margin-top: 10px;
            font: {
                family: $font-family-secondary;
                weight: 500;
                size: 1.5rem;
            }
            color: color(metallic-bronze);
        }

        &.language--current {
            position: relative;
            z-index: 2;
        }

        &:not(.language--placeholder) {
            transition: opacity var(--speed-fast) linear;
        }
    }

    .switcher__languages {
        @media (hover: none) and (pointer: coarse) {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            .inner {
                display: flex;
                align-items: center;
            }

            .language {
                &:not(.language--placeholder) {
                    --size-language: 35px;
                    --size-language-border: 6px;
                    opacity: 0;
                }

                &.language--placeholder {
                    min-width: 0;
                    height: var(--size-language);
                    transition: min-width var(--speed-slow) var(--cubic-bezier-2); /* stylelint-disable-line plugin/no-low-performance-animation-properties */
                }
            }

            .language-collapsed & {
                .language {
                    opacity: 1;

                    &.language--placeholder {
                        min-width: calc(var(--size-language) + 40px);
                    }
                }
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        @include media-breakpoint-up(lg) {
            margin-left: 30px;

            .language {
                .language__figure::before {
                    background-color: color(milk);

                    .nav-scrolling & {
                        background-color: color(metallic-bronze);
                    }

                    html.contrast-mode-avg & {
                        background-color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
                    }

                    html.contrast-mode-high & {
                        background-color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
                    }

                    html.contrast-mode-max & {
                        background-color: color(cola) !important; /* stylelint-disable-line declaration-no-important */
                    }
                }

                .language__label {
                    display: none;
                }
            }

            .switcher {
                .switcher__languages {
                    transition: opacity var(--speed-default) linear, transform var(--speed-default) var(--cubic-bezier-2);
                }

                &:hover,
                .language-collapsed & {
                    .switcher__languages {
                        opacity: 1;
                        pointer-events: all;
                        transform: translateY(0);
                        transition: opacity var(--speed-default) linear, transform var(--speed-default) var(--cubic-bezier-2);
                    }
                }
            }

            .switcher__languages {
                opacity: 0;
                width: var(--size-language);
                padding-top: 5px;
                pointer-events: none;
                position: absolute;
                top: 100%;
                left: 0;
                transform: translateY(20px);

                .inner {
                    width: 100%;
                    border-radius: var(--size-language);
                    padding: 2px 0;
                    background-color: color(milk);

                    .language {
                        &:hover {
                            opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
                        }
                    }

                    &:hover {
                        .language {
                            opacity: .5;
                        }
                    }

                    .nav-scrolling & {
                        background-color: color(metallic-bronze);
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        margin-top: 30px;
    }

    @include media-breakpoint-up(xl) {
        --size-language: 35px;
    }

    @include media-breakpoint-up(xxl) {
        --size-language: 40px;
        --size-language-border: 10px;
    }
}
