.content-loader {
    @include center-x-y;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 15px;

    @include media-breakpoint-up(md) {
        top: 50px;
    }
}

.spring-spinner {
    width: var(--size-spring-spinger);
    height: var(--size-spring-spinger);

    .spring-spinner-part {
        overflow: hidden;
        width: var(--size-spring-spinger);
        height: calc(var(--size-spring-spinger) / 2);

        &.bottom {
            transform: rotate(180deg) scale(-1, 1);
        }
    }

    .spring-spinner-rotator {
        width: var(--size-spring-spinger);
        height: var(--size-spring-spinger);
        border: calc(var(--size-spring-spinger) / 7) solid transparent;
        border-radius: 50%;
        border-top-color: color(milk);
        border-right-color: color(milk);
        transform: rotate(-200deg);
        animation: spring-spinner-animation 3s ease-in-out infinite;

        html.contrast-mode-avg & {
            border-top-color: color(metallic-bronze);
            border-right-color: color(metallic-bronze);
        }

        html.contrast-mode-high & {
            border-top-color: color(milk);
            border-right-color: color(milk);
        }

        html.contrast-mode-max & {
            border-top-color: color(cola);
            border-right-color: color(cola);
        }
    }

    &.spring-spinner--invert {
        .spring-spinner-rotator {
            border-top-color: color(metallic-bronze);
            border-right-color: color(metallic-bronze);
        }
    }

    /* stylelint-disable plugin/no-low-performance-animation-properties */
    @keyframes spring-spinner-animation {
        0% {
            border-width: calc(var(--size-spring-spinger) / 7);
        }

        25% {
            border-width: calc(var(--size-spring-spinger) / 23.33);
        }

        50% {
            border-width: calc(var(--size-spring-spinger) / 7);
            transform: rotate(115deg);
        }

        75% {
            border-width: calc(var(--size-spring-spinger) / 23.33);
        }

        100% {
            border-width: calc(var(--size-spring-spinger) / 7);
        }
    }
    /* stylelint-enable */
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity var(--speed-default) linear;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}
