.form-row {
    @include media-breakpoint-down(md) {
        .btn {
            width: 100%;
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex;

        > * {
            + * {
                margin-left: 15px;
            }
        }

        .form-group {
            flex-grow: 1;
            margin-bottom: 0;
        }
        
        .btn {
            flex-shrink: 0;
            height: auto;
        }

        &.form-row--website-switcher {
            > * {
                + * {
                    margin-left: 30px;
                }
            }
        }
    }
}