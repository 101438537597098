@import 'font-resizer';
@import 'mode-contrast';

.web-accessibility {
    .modal-title,
    .card-setting {
        @include contrast(false, false, color, false);
    }

    .card-setting {
        margin-top: 30px;

        h5 {
            @include contrast(false, false, color, false);
        }
    }
}
