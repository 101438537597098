.card {
    position: relative;

    .card__anchor {
        @include fill-w-h;
        display: block;
        z-index: 2;
    }

    .card__img {
        overflow: hidden;
        display: block; // Can be anchor
        position: relative;

        figure {
            img {
                @include fill-w-h;
                object-fit: cover;
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        .card__img {
            img {
                transition: transform var(--speed-default) var(--cubic-bezier);
            }
        }

        &:hover {
            .card__img {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
}
