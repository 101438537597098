.switch-toggle {
    height: 50px;
    margin: 20px 0;
    padding: 4px 5px;
    position: relative;

    svg:not(.switch__arrow) {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .switch__arrow-holder {
        width: 28px;
        height: 100%;
        margin-left: -14px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: rotate(90deg);

        &.forwards {
            transform: rotate(180deg);
            transition: transform var(--speed-default) cubic-bezier(.215, .61, .355, 1);
        }

        &.backwards {
            transform: rotate(0deg);
            transition: transform var(--speed-default) cubic-bezier(.215, .61, .355, 1);
        }

        &.default {
            transform: rotate(0deg);
            transition: none;
        }

        &.init {
            animation: {
                name: rotate;
                fill-mode: forwards;
                duration: .75s;
                delay: .5s;
                timing-function: cubic-bezier(.175, .885, .32, 1.275);
            }
            transition: none;

            @keyframes rotate {
                from {
                    transform: rotate(90deg);
                }

                to {
                    transform: rotate(0deg);
                }
            }
        }

        @include media-breakpoint-up(lg) {
            width: 32px;
            margin-left: -16px;
        }

        @include media-breakpoint-up(xl) {
            width: 48px;
            margin-left: -24px;
        }
    }

    .switch__arrow {
        font-size: 16px;
        position: absolute;
        left: -50%;

        path {
            fill: color(cedar);
        }

        &.switch__arrow--top {
            margin-bottom: 10px;
            bottom: 100%;
        }

        &.switch__arrow--bottom {
            margin-top: 10px;
            top: 100%;
            transform: rotate(180deg);
        }

        @include media-breakpoint-up(xl) {
            font-size: 22px;

            &.switch__arrow--top {
                margin-bottom: 15px;
            }

            &.switch__arrow--bottom {
                margin-top: 15px;
            }
        }
    }

    .switch__mask {
        path {
            @include contrast(false, false, false, fill);
            fill: color(milk);
        }
    }

    .switch__inner {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .switch__toggle {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: transform var(--speed-default);

        svg {
            path {
                fill: color(apple);
                transition: fill var(--speed-default); /* stylelint-disable-line */
            }
        }
    }

    .switch__btn-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .switch__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        position: relative;
        z-index: 2;

        span {
            overflow: hidden;
            display: block;
            line-height: 1;
            font: {
                family: $font-family-secondary;
                weight: 700;
                size: calc(12px * var(--font-size-multiplier));
            }
            color: color(metallic-bronze);
            position: relative;
            top: -1px;

            html.contrast-mode-avg & {
                color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
            }

            html.contrast-mode-high & {
                color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
            }

            html.contrast-mode-max & {
                color: color(banana) !important; /* stylelint-disable-line declaration-no-important */
            }

            @include media-breakpoint-up(lg) {
                font-size: calc(18px * var(--font-size-multiplier));
            }

            @include media-breakpoint-up(xl) {
                font-size: calc(22px * var(--font-size-multiplier));
            }
        }

        &.active {
            span {
                @include contrast(false, false, color, false);
                color: color(milk);
                transition: color var(--speed-default);
            }
        }
    }

    &.others {
        .switch__toggle {
            transform: translateX(100%);

            svg {
                path {
                    fill: color(burnt-sienna);
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        width: 100%;
        max-width: 390px;
        margin: {
            right: auto;
            left: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        max-width: 478px;
        height: 72px;
        padding: {
            top: 6px;
            bottom: 6px;
        }
    }

    @include media-breakpoint-up(xl) {
        margin: {
            top: 40px;
            bottom: 40px;
        }
    }
}
