.instagram {
    margin-bottom: 80px;
    padding: 0 20px;
    text-align: center;
    position: relative;

    .instagram__icon {
        font-size: 24px;
    }

    .instagram__icon-lines {
        width: auto;
        height: 25px;
        position: absolute;
        top: -25px;
        left: 45px;
        transform: rotate(-30deg);
    }

    .instagram__titles {
        margin-bottom: 10px;
        color: color(milk);
        position: relative;
        transform: translateY(80px);

        .instagram__title {
            margin-top: -150px;

            i {
                margin-top: 20px;
                margin-right: 20px;
            }

            &.instagram__title--no-spacing {
                margin-top: 0;
            }
        }

        .instagram__subhead {
            margin-bottom: 30px;
            color: color(metallic-bronze);
        }

        &.animation--active {
            animation: {
                name: move-up;
                duration: 1s;
                timing-function: linear;
                fill-mode: forwards;
                animation-delay: var(--speed-default);
            }
        }

        &.animate-no {
            transform: translateY(0);
            animation: none;
        }
    }

    .instagram__photos {
        display: flex;
        flex-wrap: wrap;

        .instagram__photo {
            padding: 10px;
            position: relative;
            transform: translateY(80px);
            transition: all var(--speed-default) ease-in-out;

            &::before {
                @include fill-w-h;
                background-color: color(milk);
                clip-path: url('#wobbly-box');
                content: '';
            }

            .inner {
                @include responsive-ratio(447, 450);
                position: relative;

                span,
                a {
                    @include fill-w-h;
                }

                .instagram__img {
                    @include fill-w-h;
                    object-fit: cover;
                    clip-path: url('#wobbly-box');
                }
            }

            i {
                position: absolute;
                right: 16px;
                bottom: 16px;
            }

            canvas {
                width: 300px;
                height: 300px;
            }

            &:first-child,
            &:nth-child(2) {
                margin-bottom: 10px;
            }

            &.instagram__photo--big {
                flex: 0 0 calc(60% - 10px);
            }

            &.instagram__photo--small {
                flex: 0 0 calc(40% - 10px);
                align-self: flex-start;
            }

            &.instagram__photo--flex-end {
                align-self: flex-end;
            }

            &:first-child,
            &:nth-child(4) {
                animation-timing-function: var(--cubic-bezier-3);
                animation-delay: var(--speed-default);
            }
        }

        .instagram__photo-border {
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
        }

        &.instagram__photos--spacing-top {
            margin-top: 10px;

            .instagram__photo:first-child i {
                bottom: 85px;
            }
        }

        &.animation--active {
            .instagram__photo {
                animation: {
                    name: move-up;
                    duration: 1s;
                    timing-function: linear;
                    fill-mode: forwards;
                }
            }
        }

        &.animate-no {
            .instagram__photo {
                transform: translateY(0);
                animation: none;
            }
        }
    }

    .instagram__bg-imgs {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        bottom: 35px;
        left: -5px;

        .instagram__inner {
            position: relative;

            img {
                height: 58px;
                position: relative;
                z-index: 2;
            }
        }

        &.animation--active {
            visibility: visible;
            animation: fade-in var(--speed-fast) linear alternate;
            animation-timing-function: ease-in-out;
            animation-delay: 1.2s;
            animation-fill-mode: forwards;
        }
    }

    @keyframes move-up {
        0% {
            transform: translateY(80px);
        }

        45% {
            transform: translateY(40px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @include media-breakpoint-only(xs) {
        .instagram__photo:first-child,
        .instagram__photo:nth-child(4) {
            margin-right: 10px;
        }

        .instagram__photo:nth-child(4) i {
            //
        }
    }

    @include media-breakpoint-up(sm) {
        .instagram__bg-imgs {
            bottom: 80px;
            left: -15px;
        }

        .instagram__photo:nth-child(4) i {
            bottom: 140px;
        }

        .instagram__icon-lines {
            position: absolute;
            top: -21px;
            left: 210px;
            transform: rotate(-30deg);
        }
    }

    @include media-breakpoint-up(md) {
        .instagram__titles {
            width: 50%;
            position: absolute;
            bottom: 290px;
            left: 50%;

            span {
                display: inline-flex;
                position: relative;
            }

            .instagram__icon-lines {
                top: -5px;
                left: -25px;
            }
        }

        .instagram__bg-imgs {
            bottom: 50px;

            .instagram__inner {
                img {
                    width: 94px;
                    height: auto;
                }
            }
        }

        .instagram__photos {
            max-width: 780px;
            min-height: 545px;
            margin: 0 auto;
            position: relative;

            .instagram__photo {
                position: absolute;

                i {
                    right: 23px;
                    bottom: 25px;
                }

                &.instagram__photo--big {
                    flex: 0 0 calc(40% - 10px);
                    width: 300px;
                }

                &.instagram__photo--small {
                    flex: 0 0 calc(20% - 10px);
                    width: 140px;
                }

                &:first-child {
                    margin-right: 0;
                    bottom: 75px;
                }

                &:nth-child(2) {
                    top: auto;
                    right: 0;
                    bottom: 150px;

                    i {
                        right: 18px;
                        bottom: 18px;
                    }
                }

                &:nth-child(3) {
                    margin-right: 0;
                    right: 160px;
                    bottom: 0;
                }

                &:nth-child(4) {
                    left: 160px;

                    i {
                        right: 18px;
                        bottom: 18px;
                    }
                }
            }

            &.instagram__photos--spacing-top {
                .instagram__photo:first-child i {
                    bottom: 185px;
                }
            }
        }
    }

    @include media-breakpoint-only(md) {
        .instagram__photos {
            .instagram__photo {
                .instagram__photo--big {
                    width: 240px;
                }

                &:first-child,
                &:nth-child(3) {
                    width: 240px;
                }

                &:first-child {
                    bottom: 145px;
                    left: 60px;
                }

                &:nth-child(3) {
                    right: 150px;
                    bottom: 60px;
                }
            }
        }

        .instagram__bg-imgs {
            bottom: 110px;
            left: 55px;
        }

        .instagram__titles {
            margin-top: 30px;

            .instagram__icon-lines {
                top: -23px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .instagram__titles {
            width: calc(100% - 350px);
            white-space: nowrap;
            bottom: 310px;
            left: 350px;
        }

        .instagram__bg-imgs {
            bottom: 50px;
            left: 60px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .instagram__bg-imgs {
            left: 180px;

            .instagram__inner {
                img {
                    width: 140px;
                    transform: translate(-120px, 20px);
                }
            }
        }

        .instagram__titles {
            width: calc(100% - 440px);
            bottom: 400px;
            left: 440px;
        }

        .instagram__photos {
            max-width: 980px;
            min-height: 645px;

            .instagram__photo {
                i {
                    font-size: 22px;
                    right: 16px;
                    bottom: 16px;
                }

                &.instagram__photo--big {
                    width: 400px;
                }

                &.instagram__photo--small {
                    width: 200px;
                }

                &:nth-child(2) {
                    right: -60px;
                    bottom: 190px;
                }

                &:nth-child(3) {
                    right: 160px;
                    bottom: 0;
                }

                &:nth-child(4) {
                    top: -60px;
                    left: 200px;
                }
            }
        }
    }

    @include media-breakpoint-up(fhd) {
        .instagram__bg-imgs {
            left: 275px;
        }

        .instagram__titles {
            width: auto;
            left: 480px;
        }
    }
}
