.form-control {
    @include contrast(border, background, color, false);
    width: 100%;
    min-height: var(--size-input);
    border-radius: 5px;
    padding: {
        right: var(--px-form-control);
        left: var(--px-form-control);
    }
    background-color: color(milk);
    font: {
        family: $font-family-secondary;
        size: var(--font-size-input);
    }
    color: color(metallic-bronze);
    position: relative;

    &::placeholder {
        opacity: 1;
        color: inherit;
    }

    &:disabled {
        opacity: .5;
    }

    &.form-control--border {
        border: 3px solid color(metallic-bronze);
    }

    &[readonly] {
        background-color: rgba(color(cola), .2);
    }

    &[type='search'] {
        padding-right: 45px;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button {
            @include contrast(false, false, color, false);
           color: color(metallic-bronze);
        }

        ~ .input-group-append {
            button {
                display: block;
                line-height: 1;
            }

            .icon-search {
                font-size: 20px;
                color: color(metallic-bronze);
            }
        }
    }

    &:not([type='search']):not(.form-control--border) {
        border: 0;
    }

    &:not(textarea) {
        display: flex;
        align-items: center;
    }
}

textarea.form-control {
    display: block;
    min-height: calc(var(--line-height-base) * 3);
    padding: {
        top: 10px;
        bottom: 10px;
    }
    resize: none;
}
