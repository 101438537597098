// Mr. Dodo
@font-face {
    font-family: 'Mr_dodo';
    src: url('#{$font-path}/mrdodo-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Mr_dodo';
    src: url('#{$font-path}/mrdodo-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'MineruCom-Bold';
    src: url('#{$font-path}/MineruCom-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Avenir';
    src: url('#{$font-path}/Avenir-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Avenir';
    src: url('#{$font-path}/Avenir-Roman.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
