.card.card--fruity-facts {
    margin-bottom: 15px;
    text-align: center;
    position: relative;

    .fruity-facts__bg-box {
        width: 100%;
    }

    .fruit-facts__inner {
        height: 100%;
        padding: 30px;
        position: relative;

        &::before {
            @include wobbly-box;
            content: '';
        }

        .card__img {
            padding-bottom: 66.666666%;

            figure {
                img {
                    object-fit: contain;
                }
            }
        }
    }

    .fruity-facts__description {
        font: {
            family: $font-family-secondary;
            size: 2rem;
            weight: 500;
        }
        position: relative;

        html.contrast-mode-avg & {
            color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-high & {
            color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-max & {
            color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    @media (hover: hover) and (pointer: fine) {
        transition: transform var(--speed-default) var(--cubic-bezier-3);

        &:focus-within,
        &:hover {
            transform: scale(1.05);
        }
    }

    @include media-breakpoint-up(xs) {
        .fruity-facts__description {
            font-size: 2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 25px;

        .fruity-facts__description {
            font-size: 2.4rem;
            line-height: 3.1rem;
        }
    }

    @include media-breakpoint-up(xl) {
        .fruity-facts__description {
            font-size: 2.8rem;
            line-height: 3.8rem;
        }
    }
}
