.hero.hero--products {
    background-color: color(conifer);
    position: relative;

    .hero__images {
        min-height: 225px;
        position: relative;

        .hero__image {
            &.hero__image--text,
            &.hero__image--packing,
            &.hero__image--wrapper,
            &.hero__image--sign,
            &.hero__image--fruit {
                position: absolute;
            }

            &.hero__image--text {
                width: 110px;
                top: -35px;
                left: -15px;
                transform-origin: bottom right;
                z-index: 1;
            }

            &.hero__image--packing {
                max-height: 220px;
                top: 0;
                left: 20px;
            }

            &.hero__image--wrapper {
                max-height: 250px;
                top: -35px;
                right: 30px;
            }

            &.hero__image--sign {
                max-height: 57px;
                right: 0;
                bottom: 20px;
            }

            &.hero__image--fruit {
                max-height: 75px;
                bottom: 0;
                left: 0;
                transform: scaleX(-1);
            }
        }
    }

    .hero__next-product {
        height: 266px;
        position: absolute;
        top: calc(var(--height-navbar) + 300px);
        transform: translate(100%, -50%);
        z-index: 5;
        transition: transform var(--speed-slow) cubic-bezier(.51, .01, .19, .98);

        &::before {
            display: block;
            width: 660px;
            height: 660px;
            border-radius: 50%;
            content: '';
            position: absolute;
            top: -75%;
            z-index: -1;
        }

        img {
            height: 100%;

            &:nth-child(2) {
                max-height: 105px;
                transform: translate(-55px, 10px);
            }
        }

        &.hero__next-product--right {
            right: -45px;

            &::before {
                left: 65px;
                transition: transform var(--speed-slow) cubic-bezier(.51, .01, .19, .98);
            }

            img:first-child {
                transform: translateX(-50px);
                transition: transform var(--speed-slow) cubic-bezier(.51, .01, .19, .98);
            }

            img:nth-child(2) {
                transform: translate(-175px, 10px) scaleX(-1);
                transition: transform var(--speed-slow) cubic-bezier(.51, .01, .19, .98);
                transition-delay: .2s;
            }
        }

        &.hero__next-product--left {
            left: -200px;
            transform: translate(0%, -50%);

            &::before {
                right: 165px;
                transition: transform var(--speed-slow) cubic-bezier(.51, .01, .19, .98);
            }

            img:first-child {
                transition: transform var(--speed-slow) cubic-bezier(.51, .01, .19, .98);
                transition-delay: .1s;
            }

            img:nth-child(2) {
                transition: transform var(--speed-slow) cubic-bezier(.51, .01, .19, .98);
                transition-delay: .2s;
            }
        }
    }

    .btn--prev,
    .btn--next {
        position: absolute;
        z-index: 10;
    }

    .btn.btn--next {
        right: 15px;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                + .hero__next-product--right::before {
                    transform: translateX(-250px);
                }

                + .hero__next-product--right img:first-child {
                    transform: translateX(-250px) !important; /* stylelint-disable-line declaration-no-important */
                    transition-delay: 0s;
                }

                + .hero__next-product--right img:nth-child(2) {
                    transform: translate(-495px, 10px) scaleX(-1) !important; /* stylelint-disable-line declaration-no-important */
                }
            }
        }
    }

    .btn.btn--prev {
        left: 15px;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                + .hero__next-product--left::before {
                    transform: translate(250px, 0%) !important; /* stylelint-disable-line declaration-no-important */
                }

                + .hero__next-product--left img:first-child {
                    transform: translateX(250px) !important; /* stylelint-disable-line declaration-no-important */
                }

                + .hero__next-product--left img:nth-child(2) {
                    transform: translate(185px, 10px) !important; /* stylelint-disable-line declaration-no-important */
                }
            }
        }

        @keyframes wiggle {
            0% {
                transform: translateX(0);
            }

            1% {
                transform: translateX(-3px) translateY(-2px);
            }

            2% {
                transform: translateX(3px);
            }

            3% {
                transform: translateX(-3px) translateY(2px);
            }

            4% {
                transform: translateX(3px);
            }

            5% {
                transform: translateX(-3px) translateY(-2px);
            }

            6% {
                transform: translateX(3px);
            }

            7% {
                transform: translateX(-3px) translateY(2px);
            }

            8% {
                transform: translateX(3px);
            }

            9% {
                transform: translateX(-3px translateY(-2px));
            }

            10% {
                transform: translateX(0);
            }
        }

        @include media-breakpoint-up(xs) {
            .btn--prev,
            .btn--next {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            .btn--prev,
            .btn--next {
                display: flex;
            }
        }
    }

    .hero__description {
        margin-top: 15px;
    }

    .product-fruit-minis &,
    .product-fruit-treasures & {
        .hero__images {
            .hero__image.hero__image--wrapper {
                max-height: 170px;
                top: 40px;

                @include media-breakpoint-up(md) {
                    max-height: 260px;
                    top: 45px;
                }

                @include media-breakpoint-up(xl) {
                    max-height: 300px;
                }

                @include media-breakpoint-up(xxl) {
                    max-height: 390px;
                    top: 60px;
                }
            }
        }
    }

    @include media-breakpoint-up(xs) {
        .hero__title {
            &.hero__title--spacing-top {
                padding: 30px 30px 0 30px;
            }
        }

        .hero__images {
            .product-fruit-splits & {
                max-width: 310px;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .hero__images {
            max-width: 325px;
            margin: 0 auto;
        }
    }

    @include media-breakpoint-up(md) {
        .hero__images {
            max-width: 450px;
            min-height: 350px;
            margin: 0 auto;

            .hero__image {
                &.hero__image--text {
                    width: 150px;
                    top: -60px;
                    left: -30px;
                }

                &.hero__image--packing {
                    max-height: 320px;
                    top: 0;
                    left: 20px;
                }

                &.hero__image--wrapper {
                    max-height: 370px;
                    top: -55px;
                    right: 10px;
                }

                &.hero__image--sign {
                    min-height: 80px;
                    right: -35px;
                    bottom: 50px;
                }

                &.hero__image--fruit {
                    max-height: 125px;
                    bottom: 25px;
                    left: 0;
                }
            }

            .product-fruit-splits & {
                max-width: 400px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .hero__title {
            &.hero__title--spacing-top {
                padding: 0;
            }
        }

        .hero__images {
            .hero__image {
                &.hero__image--text {
                    width: 150px;
                    top: -80px;
                    left: -35px;
                    animation: 1s rotate-in cubic-bezier(.35, .01, .25, 1) 0s 1 forwards, 5s wiggle cubic-bezier(.35, .01, .25, 1) 1.5s infinite forwards;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .hero__title {
            &.hero__title--spacing-top {
                padding: 50px 0 0 0;
            }
        }

        .hero__images {
            .hero__image {
                &.hero__image--text {
                    top: -100px;
                    left: -90px;
                    transform: none;
                }

                &.hero__image--packing {
                    max-height: 390px;
                    top: -30px;
                    left: -50px;
                }

                &.hero__image--wrapper {
                    max-height: 443px;
                    top: -93px;
                    right: -18px;
                }

                &.hero__image--sign {
                    min-height: 100px;
                    right: -75px;
                    bottom: 10px;
                }

                &.hero__image--fruit {
                    max-height: 135px;
                    bottom: -15px;
                    left: -50px;
                }
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .hero__images {
            max-width: 720px;
            min-height: 450px;

            .hero__image {
                &.hero__image--text {
                    top: -100px;
                    left: -37px;
                    transform: none;
                }

                &.hero__image--packing {
                    max-height: 560px;
                    top: -30px;
                    left: 0;
                }

                &.hero__image--wrapper {
                    max-height: 550px;
                    top: -93px;
                    right: 0;
                }

                &.hero__image--sign {
                    min-height: 140px;
                    right: -75px;
                    bottom: 10px;
                }

                &.hero__image--fruit {
                    max-height: 135px;
                    bottom: -15px;
                    left: -50px;
                }
            }

            .product-fruit-splits & {
                max-width: 560px;
            }
        }
    }
}
