.products-page {
    background-color: color(conifer);
}

.product-fruit-treasures {
    .product-info {
        .product-info__bg-fr {
            @include media-breakpoint-up(lg) {
                width: 200px;
                max-width: 200px;
                top: -40px;
                right: -100px;
            }
        }
    }
}
