.video-wrapper {
    @include responsive-ratio(9,6);
    margin-bottom: 20px;
    position: relative;

    video {
        @include fill-w-h;
        object-fit: cover;
    }
}

.section.section--how-we-make-it {
    --pt-section: 80px;
    --pb-section: 60px;
    text-align: center;

    .section__intro {
        margin-bottom: 40px;
    }

    .steps-holder {
        position: relative;
        z-index: 2;

        .step-item {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            width: var(--step-image-size);
        }

        .step-image-wrapper {
            position: relative;
        }

        .step-image {
            overflow: visible;
            width: var(--step-image-size);
            height: var(--step-image-size);
            border-radius: 50%;
            position: relative;

            img {
                @include fill-w-h;

                &.step-image--small {
                    width: 90px;
                    height: auto;
                    top: auto;
                    bottom: -20px;
                    left: -50px;

                    &.step-image--small--right {
                        right: -30px;
                        left: auto;
                    }

                    @include media-breakpoint-up(lg) {
                         width: 120px;
                     }

                    @include media-breakpoint-up(xl) {
                        width: 140px;
                    }
                }
            }
        }
    }

    .step-content {
        margin-top: 25px;
        font-family: $font-family-secondary;
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.4rem;
    }

    .switch-holder {
        padding: 40px 0;
    }

    .switch-wrapper {
        display: flex;
        width: 200%;
        transition: transform 1s cubic-bezier(.215, .61, .355, 1);

        .tns-ovh {
            overflow: visible;
        }

        .switch-content {
            width: 50%;
            position: relative;
            transition: opacity var(--speed-slow) linear;

            &.switch-content--how-others-do-it {
                opacity: 0;
            }
        }

        &.others {
            transform: translateX(-50%);

            .switch-content {
                &.switch-content--how-we-do-it {
                    opacity: 0;
                }

                &.switch-content--how-others-do-it {
                    opacity: 1;
                }
            }
        }
    }

    .dotted-line {
        @include center-x-y;
        width: 45%;
        top: calc(var(--step-image-size) / 2);
        z-index: 0;

        svg {
            width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        .steps-holder {
            margin: {
                right: var(--step-image-margin);
                left: var(--step-image-margin);
            }
        }
    }

    @include media-breakpoint-up(md) {
        .steps-holder {
            display: flex;
            justify-content: space-around;
        }
    }

    @include media-breakpoint-up(xl) {
        --pt-section: 90px;
        --pb-section: 140px;

        .switch-holder {
            padding: {
                top: 30px;
                bottom: 45px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .section__intro {
            margin-bottom: 30px;
        }

        .step-content {
            width: 370px;
            margin-top: 50px;
            font-size: 2.8rem;
            line-height: 3rem;
        }
    }
}
