html {
    font-size: var(--font-size-html);
}

body {
    font: {
        family: $font-family-base;
        weight: 400;
        size: var(--font-size-base);
    }
    line-height: var(--line-height-base);
    color: color(pickledbluewood);

    b,
    strong {
        font-weight: 700;
    }
}

body,
button,
input,
select,
textarea {
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga', 'kern';
}

// Headings
h1,
h2,
h3,
h5,
.h1,
.h2,
.h3,
.h5 {
    font-weight: 700;
}

h4,
h6 {
    font-weight: 600;
}

h1,
.h1 {
    @include heading(h1);
}

h2,
.h2 {
    @include heading(h2);
}

h3,
.h3 {
    @include heading(h3);
}

h4,
.h4 {
    @include heading(h4);
}

h5,
.h5 {
    @include heading(h5);
}

h6,
.h6 {
    @include heading(h6);
}

.h1--alt {
    font-size: 4.5rem;
    line-height: 1;

    @include media-breakpoint-up(lg) {
        font-size: 6.5rem;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 8rem;
    }
}

// Textual
main {
    p,
    ul,
    ol,
    table,
    blockquote {
        &:not([class*='-none']) {
            + *:not(.btn) {
                margin-top: 15px;

                @include media-breakpoint-up(xl) {
                    margin-top: 20px;
                }
            }
        }
    }

    ul:not(.list-unstyled) {
        li {
            padding-left: 24px;
            font-weight: 400;
            position: relative;

            &::before {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: color(metallic-bronze);
                content: '';
                position: absolute;
                top: calc((var(--line-height-base) - 8px) / 2);
                left: 0;
            }
        }
    }
}

// Anchors
a,
p a,
ul li a,
ol li a,
small a,
td a,
.link {
    font-weight: 700;
    text-decoration: underline;

    * + * {
        //
    }

    &:hover {
        //
    }
}

picture,
figure {
    figcaption {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;
            color: inherit;
        }
    }
}
