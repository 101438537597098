.contrast-mode-set {
    display: flex;
    border: 1px solid color(metallic-bronze);

    .contrast-mode-set__toggle {
        @include contrast(border, false, color, false);
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 90px;
        transition: background-color var(--speed-default) ease-out;

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            margin-bottom: 10px;
            border: 1px solid transparent;
            border-radius: 100%;
            font: {
                weight: 900;
                size: 16px;
                style: normal;
            }
            line-height: 1;
        }

        span {
            font-size: 1rem;
            line-height: 1;
        }

        + .contrast-mode-set__toggle {
            border-left: 1px solid color(metallic-bronze);
        }

        &.contrast-mode-set__toggle--avg {
            i {
                border-color: color(metallic-bronze);
                background-color: color(milk);
                color: color(metallic-bronze);
            }
        }

        &.contrast-mode-set__toggle--high {
            i {
                border-color: color(milk);
                background-color: color(metallic-bronze);
                color: color(milk);
            }
        }

        &.contrast-mode-set__toggle--max {
            i {
                border-color: color(cola);
                background-color: color(banana);
                color: color(cola);
            }
        }

        &.active {
            background-color: color(apple);
            color: color(milk);
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: color(apple);
                color: color(milk);
            }
        }
    }
}
