.filter {
    .filter__group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        > * {
            &:not(:last-child) {
                margin: {
                    right: 15px;
                    bottom: 15px;
                }
            }
        }

        .filter__btn {
            width: var(--width-filter-button);
            text-align: center;
            word-break: break-word;
            cursor: pointer;

            figure {
                padding-top: 100%;
                position: relative;

                img {
                    @include fill-w-h;
                    object-fit: contain;
                }
            }

            &:focus-within {
                @include focus;
            }
        }

        .filter__btn__label {
            margin-top: 10px;
        }

        .filter__btn__indicator {
            display: block;
            position: relative;

            .btn {
                opacity: 0;
                transition: opacity var(--speed-default) linear;
            }

            .animated-lines {
                @include wobbly-lines-animation;
                width: 25px;
                position: absolute;
                top: 20px;
                left: -15px;
                transform: rotate(-35deg);
            }
        }

        .filter__btn__input {
            @include fill-w-h;
            opacity: 0;
            z-index: -1;
        }

        &.filtergroup__active {
            .filter__btn__input {
                &:checked {
                    + .filter__btn__indicator {
                        .btn {
                            opacity: 1;
                        }
                    }
                }

                &:not(:checked) {
                    + .filter__btn__indicator {
                        opacity: .5;
                    }
                }
            }
        }
    }
}