.parallax-images {
    display: flex;
    width: 100%;
    height: var(--height-parallax-images);
    margin-bottom: -20px;
    pointer-events: none;
    position: absolute;
    //top: calc(-1 * ((var(--height-wave) + (var(--margin-parallax-image) / 2))));
    top: -80px;
    right: 0;
    bottom: 100%;
    left: 0;
    z-index: 10;
    transition: opacity var(--speed-fast) linear;

    .parallax-image__left,
    .parallax-image__right {
        height: 100%;
        margin: 0 var(--margin-parallax-image);
        position: absolute;
        right: 0;
    }

    .parallax-image__left {
        right: auto;
        left: 0;
    }

    img {
       height: 100%;
    }

    .wobbly-lines {
        @include wobbly-lines-animation;
        height: 15px;
        position: absolute;
        top: 0;
        left: 33%;
        transform: translateY(-100%) rotate(-25deg);

        @include media-breakpoint-up(lg) {
            height: 25px;
        }
    }

    .parallax-image__right {
        .wobbly-lines {
            right: 33%;
            left: auto;
            transform: translateY(-100%) rotate(25deg);
        }
    }

    @include media-breakpoint-down(sm) {
        img {
            width: 160px;
        }
    }

    @include media-breakpoint-up(md) {
        top: -60px;
    }

    @include media-breakpoint-down(lg) {
        .parallax-image__left {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        top: -150px;
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: -40px;
    }
}
