.waving-hand {
    display: none;

    @include media-breakpoint-up(xl) {
        display: block;
        position: fixed;
        right: -100px;
        bottom: 40%;
        transform: translateX(100%);
        transform-origin: right center;
        z-index: 9995;

        img {
            width: 425px;
        }

        &.animate {
            animation: {
                name: waving-hand-animation;
                duration: 5s;
                fill-mode: forwards;
                timing-function: cubic-bezier(.45, 0, 0, .99);
            }
        }

        @keyframes waving-hand-animation {
            0% {
                transform: translateX(100%) rotate(-50deg);
            }

            15% {
                transform: translateX(0) rotate(5deg);
            }

            30% {
                transform: rotate(10deg);
            }

            45% {
                transform: rotate(-10deg);
            }

            60% {
                transform: rotate(10deg);
            }

            70% {
                transform: rotate(0);
            }

            80% {
                transform: rotate(0);
            }

            100% {
                transform: translateX(100%) rotate(-50deg);
            }
        }
    }
}
