.section.section--ingredients {
    --pt-section: 0;
    --pb-section: 0;

    @include media-breakpoint-up(md) {
        --pt-section: 30px;
        background: color(conifer);
    }

    @include media-breakpoint-up(lg) {
        --pb-section: 40px;
    }
}
