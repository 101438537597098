.social-list {
    display: flex;
    align-items: center;

    .btn {
        margin-top: 0;

        + .btn {
            margin-left: 5px;
        }
    }

    @include media-breakpoint-down(md) {
        justify-content: center;
    }
}
