.form {
    text-align: left;
}

.form-group,
.input-group {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    label {
        font-weight: 700;
        cursor: pointer;
    }

    &.form-group--upload {
        overflow: hidden;

        .form-control {
            opacity: 0;
        }

        label {
            @include fill-w-h;
            overflow: hidden;
            display: block;
            padding: 0 20px;
            font-family: $font-family-secondary;
            font-weight: 500;
            line-height: var(--size-input);
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            z-index: 5;

            html.contrast-mode-avg & {
                color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
            }

            html.contrast-mode-high & {
                color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
            }

            html.contrast-mode-max & {
                color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    html.contrast-mode-avg & {
                        color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
                    }

                    html.contrast-mode-high & {
                        color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
                    }

                    html.contrast-mode-max & {
                        color: color(cola) !important; /* stylelint-disable-line declaration-no-important */
                    }
                }
            }
        }

        svg {
            @include fill-w-h;
            pointer-events: none;
        }
    }
}

.input-group {
    .input-group-append {
        @include center-y('right', 20px);
    }
}
