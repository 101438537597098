.social {
    margin: {
        top: 40px;
        right: auto;
        bottom: 40px;
        left: auto;
    }

    .social__title {
        color: color(milk);
    }

    .social__inner {
        display: flex;
        width: fit-content;
        margin: {
            top: 20px;
            right: auto;
            bottom: auto;
            left: auto;
        }

        .social__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
            color: color(milk);
            text-decoration: none;

            i {
                font-size: 20px;
                z-index: 1;
            }

            @include media-breakpoint-up(xxl) {
                --height-btn: 55px;
            }
        }
    }
}
