.modal.modal--card-collection {
    @include contrast(false, background, color, false);
    margin: 0;
    background-color: color(conifer);

    button[data-dismiss='modal'] {
        display: inline-flex;
        align-items: center;
        width: auto;
        color: color(metallic-bronze);
        position: relative;
        margin: {
            bottom: 25px;
            left: auto;
        }
        font: {
            family: $font-family-secondary;
            weight: 500;
            size: 1.7rem;
        }

        i {
            margin-left: 12px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 2rem;
        }
    }

    .modal-dialog {
        max-width: 100%;
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        min-height: calc(var(--vh, 1vh) * 100);
        padding: {
            top: 25px;
            bottom: 40px;
        }
        background-color: inherit;
        box-shadow: none;

        html.contrast-mode & {
            background: transparent !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    .modal-header {
        width: 100%;
        margin-bottom: 20px;

        .modal-title {
            margin-bottom: 0;
        }
    }

    .modal-body {
        flex-grow: 1;
        width: 100%;

        .card {
            width: 50%;
            padding: 7px;
            cursor: default;

            img {
                border-radius: 15px;
                box-shadow: 0 3px 18px 0 rgba(color(cola), .2);
                pointer-events: none;
            }

            &.card--flip {
                position: relative;
                perspective: 1000px;

                .card--flip__inner {
                    display: block;
                    width: 100%;
                    height: 100%;
                    transform-style: preserve-3d;
                    transition: transform var(--speed-slow);
                }

                img {
                    backface-visibility: hidden;

                    &.card--flip__back {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: rotateY(180deg);
                    }
                }

                &.flipped {
                    .card--flip__inner {
                        transform: rotateY(180deg);
                    }
                }

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        .card--flip__inner {
                            transform: rotateY(180deg);
                        }
                    }
                }
            }

            &:nth-child(odd) {
                transform: rotate(-1deg);
            }

            &:nth-child(even) {
                transform: rotate(1deg);
            }
        }
    }

    .modal-footer {
        width: 100%;

        .paginate-links {
            opacity: 0;
            justify-content: center;
            transition: opacity var(--speed-default) ease;

            .btn {
                font-size: 4.2rem;
                color: color(milk);
            }
        }
    }

    @include media-breakpoint-up(md) {
        button[data-dismiss='modal'] {
            margin-bottom: 60px;
        }

        .modal-content {
            padding-top: 25px;
        }

        .modal-header {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .modal-header__box {
                &:last-child {
                    margin-left: auto;
                    text-align: right;
                }
            }

            .btn {
                margin-top: 0;
            }
        }

        .modal-body {
            .card {
                width: 20%;
                padding: 10px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        button[data-dismiss='modal'] {
            margin-bottom: 120px;
        }

        .modal-content {
            padding-top: 40px;
        }

        .modal-header {
            margin-bottom: 50px;
        }

        .modal-body {
            .card {
                width: unquote(100 / 7 + '%');
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .modal-header {
            .modal-header__box {
                flex: 1;

                &:first-child {
                    margin-right: auto;
                }
            }
        }
    }
}
