@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.slider-product-wrapper {
	position: relative;

	.arrows {
		display: none;

		.btn--prev,
		.btn--next {
			position: absolute;
			top: calc(50% - var(--size-button-circle));
			z-index: 10;
		}

		.btn.btn--next {
			right: -20px;
		}

		.btn.btn--prev {
			left: -20px;
		}

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	.tns-controls {
		display: none;
	}
}

.slider-product {
	margin: {
		top: 40px;
		bottom: 55px;
	}

	.slider-product__slider {
		margin: 0 auto;

		.product__view {
			height: 310px;
			padding: 30px;
			position: relative;
			transition: transform var(--speed-default) ease-in-out;

			&.product__view--default {
				opacity: 0;
				filter: drop-shadow(10px 10px 0 rgba(color(cola), .1));

				@include media-breakpoint-down(sm) {
					opacity: 1;
				}
			}
		}

		.product {
			text-decoration: none;
			position: relative;

			.product__link {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 12;
			}

			.animated-lines {
				transition: opacity var(--speed-default) ease;
			}

			.animate-no {
				.animated-lines {
					display: none;
				}

				.product__inner__rotate {
					transform: rotate(0) !important; /* stylelint-disable-line declaration-no-important */
					animation: none !important; /* stylelint-disable-line declaration-no-important */
				}

				.product__content {
					transition: none !important; /* stylelint-disable-line declaration-no-important */
				}
			}

			.product__inner {
				display: inline-flex;
				position: relative;
			}

			.product__fruit-type {
				width: 110px;
				position: absolute;
				right: -30px;
				bottom: -40px;
			}

			.product__description {
				opacity: 0;
				width: 100%;
				position: absolute;
				top: 100%;
				left: 0;
				transition: opacity var(--speed-default) ease-in-out;
			}

			&:not(.current) {
				.animated-lines {
					opacity: 0;
				}
			}

			&.current {
				.animated-lines {
					opacity: 1;
				}
			}
		}

		&.related-products__slider {
			margin: 0 auto;
		}

		.product-groups & {
			visibility: hidden;
			display: flex;

			&.tns-slider {
				visibility: visible;
			}
		}
	}

	.slider-product__categories {
		display: flex;
		justify-content: center;
		align-items: center;

		.slider-product__divider {
			margin: 0 10px;
			font-size: 36px;
			color: rgba(color(metallic-bronze), .5);
		}

		.slider-product__category {
			font: {
				family: $font-family-secondary;
				size: 1.5rem;
				weight: 500;
			}
			color: color(metallic-bronze);

			.btn {
				margin: 0;
				padding: 0;

				&:not(.active) {
					html.contrast-mode-avg & {
						color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
					}

					html.contrast-mode-high & {
						color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
					}

					html.contrast-mode-max & {
						color: color(cola) !important; /* stylelint-disable-line declaration-no-important */
					}

					@media (hover: hover) and (pointer: fine) {
						&:hover {
							path {
								@include contrast(false, false, false, fill);
							}

							html.contrast-mode-avg & {
								color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
							}

							html.contrast-mode-high & {
								color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
							}

							html.contrast-mode-max & {
								color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
							}
						}
					}
				}

				@include media-breakpoint-down(sm) {
					min-width: inherit;
				}
			}

			.btn.active {
				padding: 0 10px;
				cursor: default;
				pointer-events: none;

				&:hover {
					svg,
					path {
						fill: color(milk) !important; /* stylelint-disable-line declaration-no-important */
					}
				}

				html.contrast-mode-avg & {
					color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
				}
			}
		}
	}

	.tns-ovh {
		padding: 0 100px;
	}

	@keyframes product-rotate-out {
		0% {
			transform: rotate(-15deg);
		}

		100% {
			transform: rotate(0);
		}
	}

	@include media-breakpoint-only(xs) {
		.tns-ovh {
			overflow: visible;
		}
	}

	@include media-breakpoint-only(sm) {
		.tns-ovh {
			padding: 0 150px;
		}
	}

	@include media-breakpoint-only(md) {
		.tns-ovh {
			padding: 0;
		}
	}

	@include media-breakpoint-down(md) {
		.tns-item {
			&:not(.tns-slide-active) {
				.product__inner {
					transform: scale(.7);
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.slider-product__btn {
			display: none;
		}

		.slider-product__slider {
			.product {
				.product__view {
					padding: 0;

					&.product__view--default {
						opacity: 1;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.product {
			width: 25%;
		}

		.slider-product__slider {
			.product__view {
				height: 180px;
				padding: 0;

				&.product__view--default {
					opacity: 1;
				}
			}

			.home-page & {
				display: flex;
				justify-content: center;

				.product {
					padding: {
						right: 50px;
						left: 50px;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.tns-ovh {
			overflow: hidden;
			padding: 0;

			.product-fruit-splits & {
				overflow: visible;
			}
		}

		.tns-item {
			position: relative;
		}

		.slider-product__slider {
			.product__view {
				height: 260px;
			}

			.product {
				.product__fruit {
					opacity: 0;
					max-width: 85px;
					position: absolute;
					right: -5px;
					bottom: 0;
					transform: rotate(38deg) scale(.3);
					transition: opacity var(--speed-default) ease-in-out .5s, transform var(--speed-default) ease-in-out .5s;
				}

				.product__content {
					max-width: 90px;
					position: absolute;
					top: 8px;
					right: 0;
					transform: translate(-20px, 15px);
					z-index: -1;
					transition: transform var(--speed-slow) ease-in .5s, opacity var(--speed-slow) ease-in .5s;
					transition-timing-function: var(--cubic-bezier-3);
				}

				.product__wrapper {
					position: relative;
				}

				.product__inner {
					width: 100%;

					.product__inner__rotate {
						margin: 0 auto;
						animation-name: product-rotate-out;
						animation-duration: .5s;
						animation-fill-mode: both;
					}

					@media (hover: hover) and (pointer: fine) {
						.product__view--default {
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							animation-name: fade-in;
							animation-duration: 1s;
							animation-timing-function: var(--cubic-bezier-3);
							animation-delay: .5s;
							animation-fill-mode: forwards;
						}

						&:not(.animate-no) {
							&:focus-within,
							&:hover {
								.product__link[title='Raspberry Pineapple'],
								.product__link[title='Strawberry Apple'] {
									+ .product__inner__rotate {
										.product__fruit {
											transform: scale(1.5);
										}
									}
								}

								.product__inner__rotate {
									animation-name: rotate-in;
									animation-duration: 1s;
									animation-timing-function: var(--cubic-bezier-3);
									animation-fill-mode: forwards;

									.product__view--default {
										display: none;
									}

									.product__fruit {
										opacity: 1;
										transform: scale(1);
										transition: opacity var(--speed-default) ease-in-out .2s, transform var(--speed-default) ease-in-out .2s;
										transition-timing-function: var(--cubic-bezier-3);
									}

									.product__content {
										visibility: visible;
										transform: translate(15px, -15px);
										transition: transform var(--speed-slow) ease-in .2s, opacity var(--speed-slow) ease-in .2s;
										transition-timing-function: var(--cubic-bezier-3);

										.product-fruit-splits & {
											max-width: 200px;
											top: -110px;
										}
									}
								}

								.product__description {
									opacity: 1;
								}
							}
						}
					}
				}

				&:focus {
					.product__inner__rotate {
						animation-name: rotate-in;
						animation-duration: 1s;
						animation-timing-function: var(--cubic-bezier-3);
						animation-fill-mode: forwards;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.slider-product__slider {
			.product__view {
				height: 330px;

				&.product__view--default {
					opacity: 0;
				}
			}

			.product {
				padding: {
					top: 70px;
					bottom: 70px;
				}

				.product__fruit {
					max-width: 85px;
					right: -18px;
					bottom: -10px;
				}

				.product__content {
					max-width: 98px;
					right: -5px;
				}

				.product__image {
					.animated-lines {
						top: 55px;
						left: 0;
					}
				}
			}
		}
	}

	@media only screen and (width: 1366px) {
		.slider-product__slider {
			.product__view {
				&.product__view--default {
					opacity: 1;
				}
			}
		}
	}

	@include media-breakpoint-up(xxl) {
		.slider-product__slider {
			.product__view {
				height: 400px;
			}

			.product {
				.product__content {
					max-width: 125px;
				}
			}
		}
	}

	@include media-breakpoint-up(fhd) {
		.slider-product__slider {
			.product {
				.product__content {
					max-width: 130px;
				}

				.product__fruit {
					max-width: 100px;
					right: -40px;
					bottom: -20px;
				}
			}
		}
	}
}

.product-paws,
.product-fruit-minis,
.product-fruit-treasures, {
	.section__title {
		color: color(milk);
	}

	.slider-product__slider {
		.product__view {
			height: auto;
		}

		@include media-breakpoint-up(md) {
			.product__inner {
				padding: {
					right: 30px;
					left: 30px;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.product__inner {
				padding: {
					right: 70px;
					left: 70px;
				}
			}
		}
	}

	.tns-ovh {
		@include media-breakpoint-down(sm) {
			padding: {
				right: 60px;
				left: 60px;
			}
		}
	}
}
