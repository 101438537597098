// Spacing
$spacer: 10px;
$spacers: (
    0: 0,
    5: ($spacer / 2),
    10: $spacer,
    15: ($spacer * 1.5),
    20: ($spacer * 2),
    25: ($spacer * 2.5),
    30: ($spacer * 3),
    35: ($spacer * 3.5),
    40: ($spacer * 4),
    45: ($spacer * 4.5),
    50: ($spacer * 5),
    55: ($spacer * 5.5),
    60: ($spacer * 6),
    65: ($spacer * 6.5),
    70: ($spacer * 7),
    75: ($spacer * 7.5),
    80: ($spacer * 8),
    85: ($spacer * 8.5),
    90: ($spacer * 9),
    95: ($spacer * 9.5),
    100: ($spacer * 10)
);

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 568px, // iPhone SE landscape
    md: 768px, // Tablet portrait
    lg: 1024px, // Tablet landscape
    xl: 1280px, // Desktop small
    xxl: 1440px, // Desktop regular
    fhd: 1920px, // Desktop large
);

// Grid columns
$grid-gutter-width: 30px;
$grid-gutter-width-md: 40px;
$grid-gutter-width-xxl: 50px;
$grid-gutter-width-fhd: 60px;

// Grid containers
$container-mw-sm: 768px;
$container-mw-md: 1024px;
$container-mw-lg: 1000px;
$container-mw-xl: 980px;
$container-mw-xxl: 1200px;
$container-mw-fhd: 1400px;
$container-max-widths: (
    sm: $container-mw-sm,
    md: $container-mw-md,
    lg: $container-mw-lg + $grid-gutter-width-md,
    xl: $container-mw-xl + $grid-gutter-width-md,
    xxl: $container-mw-xxl + $grid-gutter-width-xxl,
    fhd: $container-mw-fhd + $grid-gutter-width-fhd
);
