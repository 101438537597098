.section.section--fruity-detail {
    .images__image--fruit {
        @include media-breakpoint-up(lg) {
            max-width: 170px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 185px;
        }

        @include media-breakpoint-up(fhd) {
            max-width: 215px;
        }
    }

    p {
        max-width: 265px;
        margin: 0 auto;

        @include media-breakpoint-up(sm) {
            max-width: 525px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 735px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 740px;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 820px;
        }

        @include media-breakpoint-up(fhd) {
            max-width: 1030px;
        }
    }

    h2 {
        @include media-breakpoint-up(xl) {
            font-size: 5rem;
        }
    }

    .btn {
        width: auto;
        min-width: auto;
    }

    .images {
        padding-top: 175px;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }
    }

    .bottom-buttons {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        a {
            width: auto;
            min-width: auto;

            &:first-child {
                margin-right: 20px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 0;
    }
}
