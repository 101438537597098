/* stylelint-disable color-no-hex  */
$colors: (
    // Primary
        apple: #57b947,
        metallic-bronze: #472e19,
    // Secondary
        atlantis: #8cc83c,
        bright-sun: #ffdd35,
        burnt-sienna: #f0645a,
        christi: #63a70a,
        conifer: #9dd354,
        lavender-pink: #fa9bcb,
        sea-buckthorn: #fab432,
        wisteria: #bf74c0,
        pink-salmon: #ff8b9a,
        seagull: #7eccee,
        illusion: #f496df,
    // Grayscale
        alto: #dbdbdb,
        cola: #000,
        milk: #fff,
    // Secondary
        california: #fea30b, // Instagram
        chambray: #3b4e9b, // Facebook
        permission: #ff6557, // Youtube
        grass: #00be00, // Whatsapp
        azure-radiance: #00a2f9, // Twitter
    // Accessibility colors
        banana: #fff400,
        blueribbon: #0067f4,
    // Validation
        guardsman-red: #c00c00,
        japanese-laurel: #008000,
);
/* stylelint-enable color-no-hex  */

// Color generation
@function color($color) {
    @if map-has-key($colors, $color) {
        @return map-get($colors, $color);
    }

    @return null;
}

/* stylelint-disable declaration-no-important */
@each $name, $value in $colors {
    .text-#{$name} {
        color: $value !important;

        > * {
            color: inherit;
        }

        html.contrast-mode-avg & {
            color: color(metallic-bronze) !important;
        }

        html.contrast-mode-high & {
            color: color(milk) !important;
        }

        html.contrast-mode-max & {
            color: color(milk) !important;
        }
    }

    .section--ingredients-bg-#{$name},
    .bg-#{$name} {
        @include contrast(false, background, color, false);
        background-color: $value !important;
        transition: all var(--speed-default) ease-in-out;

        .wave {
            path {
                fill: $value !important;
            }
        }
    }

    .bg-before-#{$name} {
        &::before {
            background-color: $value !important;
        }
    }

    .fill-#{$name} {
        svg {
            filter: drop-shadow(0 0 0 darken($value, 20%));

            path {
                @include contrast(false, false, false, fill);
                fill: $value !important;
            }
        }

        html.contrast-mode-high & {
            color: color(metallic-bronze) !important;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                svg {
                    path {
                        fill: darken($value, 10%) !important;

                        html.contrast-mode-avg & {
                            fill: color(milk) !important;
                        }

                        html.contrast-mode-high & {
                            fill: color(milk) !important;
                        }

                        html.contrast-mode-max & {
                            fill: color(milk) !important;
                        }
                    }

                    html:not(.contrast-mode) & {
                        filter: drop-shadow(1px 3px 0 darken($value, 20%));
                    }
                }

                html.contrast-mode-avg & {
                    color: color(metallic-bronze) !important;
                }

                html.contrast-mode-high & {
                    color: color(metallic-bronze) !important;
                }

                html.contrast-mode-max & {
                    color: color(cola) !important;
                }

                html:not(.contrast-mode) & {
                    filter: drop-shadow(1px 2px 0 darken($value, 20%));
                }
            }
        }
    }
}

.fill-no-hover {
    svg {
        filter: none !important;
    }

    &:hover {
        filter: none !important;

        svg {
            filter: none !important;
        }

        svg path {
            filter: none !important;
            fill: color(milk) !important;
        }
    }
}

.bg-apple,
.bg-atlantis,
.bg-bright-sun,
.bg-burnt-sienna,
.bg-christi,
.bg-conifer,
.bg-lavender-pink,
.bg-sea-buckthorn,
.bg-wisteria,
.bg-pink-salmon,
.bg-seagull,
.bg-illusion {
    @include contrast(false, false, color, false);
    color: color(milk);

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include contrast(false, false, color, false);
        color: color(milk);
    }

    .accordion-item {
        border-color: rgba(color(milk), .3);
    }

    main & {
        ul:not(.list-unstyled) {
            li {
                &::before {
                    background-color: color(milk);
                }
            }
        }
    }
}

.bg-milk {
    @include contrast(false, background, color, false);

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include contrast(false, false, color, false);
    }

    main & {
        ul:not(.list-unstyled) {
            li {
                &::before {
                    background-color: color(metallic-bronze);
                }
            }
        }
    }
}

/* stylelint-enable declaration-no-important */
