.image-mask-container {
    position: relative;

    .mask {
        @include fill-w-h;
    }

    img {
        width: 100%;
        border-radius: 50%;
        transform: scale(.96);
    }
}

.image-mask {
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}