.site-footer {
    --padding-top: 60px;
    --padding-bottom: 70px;
    padding: {
        top: var(--padding-top);
        bottom: var(--padding-bottom);
    }
    position: relative;
    z-index: 15;

    .addresses {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        address {
            margin-bottom: 25px;

            @include media-breakpoint-up(md) {
                flex: 0 0 30%;
                margin-bottom: 0;
                font-size: 24px;
            }
        }
    }

    .footer__title {
        margin-bottom: 10px;
        color: color(milk);
    }

    .site-footer__wave {
        overflow-x: hidden;
        width: 100%;
        height: var(--height-wave);
        margin-top: 1px;
        position: relative;
        top: calc(((var(--height-wave) + var(--padding-top))) * -1);
        z-index: -1;

        .wave {
            position: absolute;
            top: calc(var(--height-wave));
        }
    }

    .footer__img-wrapper {
        opacity: 0;
        overflow: hidden;
        width: 100%;
        height: 500px;
        margin-top: -75px;
        position: absolute;
        right: 0;
        transform: translateY(80px);

        .footer__bg-img {
            height: 140px;
            position: absolute;
            top: 0;
            right: -10px;

            .footer-bear-st0 {
                html.contrast-mode-high & {
                    fill: color(cola) !important; /* stylelint-disable-line declaration-no-important */
                }
            }

            &.footer__bg-img--small {
                height: 60px;
                top: 80px;
                right: 70px;
            }

            &.footer__bg-img--big {
                display: none;
            }
        }

        &:not(.footer__img-wrapper--still) {
            &.animation--active {
                animation: {
                    name: move-up, fade-in;
                    duration: 1s;
                    timing-function: linear;
                    fill-mode: forwards;
                }
            }
        }

        &.footer__img-wrapper--still {
            opacity: 1;
            height: 300px;
            transform: translateY(0);

            .footer__bg-img {
                @include media-breakpoint-up(xl) {
                    height: 200px;

                    &.footer__bg-img--small {
                        height: 130px;
                        right: 70px;
                    }
                }

                @include media-breakpoint-up(fhd) {
                    height: 230px;

                    &.footer__bg-img--small {
                        height: 160px;
                        right: 100px;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                margin-top: -70px;
                left: -50px;
            }
        }

        @include media-breakpoint-down(lg) {
            height: 150px;
        }
    }

    .footer__nav {
        a,
        span {
            @include contrast(false, false, color, false);
            display: inline-block;
            padding: 10px 0;
            font-weight: 500;
            line-height: 1;
            color: color(milk);
            text-decoration: none;
            font: {
                family: $font-family-secondary;
                size: 1.6rem;
            }
        }

        @media (hover: hover) and (pointer: fine) {
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @include media-breakpoint-up(md) {
            ul {
                display: flex;
                justify-content: center;
            }

            a,
            span {
                margin: 0 10px;
                font-size: 1.4rem;
            }
        }

        @include media-breakpoint-up(xxl) {
            a,
            span {
                font-size: 1.5rem;
            }
        }
    }

    @keyframes move-up {
        0% {
            transform: translateY(80px);
        }

        45% {
            transform: translateY(40px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @include media-breakpoint-only(xs) {
        .footer__img-wrapper {
            margin-top: -170px;
        }
    }

    @include media-breakpoint-up(lg) {
        --padding-top: 0px;  /* stylelint-disable-line */
        --padding-bottom: 55px;

        .footer__title {
            margin-bottom: 20px;
        }

        .footer__img-wrapper {
            max-width: 300px;
            margin-top: -230px;
            left: 0;

            .footer__bg-img {
                top: auto;
                right: 0;
                bottom: 21px;

                &.footer__bg-img--big {
                    display: block;
                    height: 100%;
                    left: 0;

                    path#paw {
                        transform-origin: 34% 50%;
                        animation-name: footer-wave-animation;
                        animation-duration: 5s;
                        animation-iteration-count: infinite;

                        @keyframes footer-wave-animation {
                            0% {
                                transform: rotate(0deg);
                            }

                            10% {
                                transform: rotate(6deg);
                            }

                            20% {
                                transform: rotate(-3deg);
                            }

                            30% {
                                transform: rotate(6deg);
                            }

                            40% {
                                transform: rotate(-2deg);
                            }

                            50% {
                                transform: rotate(6deg);
                            }

                            60% {
                                transform: rotate(-1deg);
                            }

                            70% {
                                transform: rotate(4deg);
                            }

                            80% {
                                transform: rotate(0deg);
                            }

                            100% {
                                transform: rotate(0deg);
                            }
                        }
                    }
                }

                &.footer__bg-img--small {
                    height: 100px;
                    top: auto;
                    right: 60px;
                    bottom: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        --padding-bottom: 40px;

        .footer__title {
            margin-bottom: 40px;

            &.footer__title--small-spacing {
                margin-bottom: 10px;
            }
        }
    }
}
