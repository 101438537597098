.section.section--article {
    article {
        color: color(metallic-bronze);

        .author {
            display: block;
            margin-top: 15px;
            font-family: $font-family-secondary;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.6rem;

            .signature {
                display: block;
            }
        }

        .written-by {
            margin-top: 20px;

            img {
                min-width: 200px;
            }
        }

        .article__inner {
            word-break: break-word;
            position: relative;
            padding: {
                top: 5px;
                right: 25px;
                bottom: 20px;
                left: 25px;
            }

            &::before,
            &::after {
                width: 100%;
                height: 30px;
                background: {
                    position: top left;
                    repeat: no-repeat;
                    size: 100% 100%;
                }
                content: '';
                position: absolute;
                top: -28px;
                left: 0;
            }

            &::before {
                background-image: url('~/../../images/content-top-bg.svg');
            }

            &::after {
                background-image: url('~/../../images/content-bottom-bg.svg');
                top: 100%;
            }

            html.contrast-mode & {
                &::after,
                &::before {
                    display: none;
                }
            }
        }

        .article__table-wrapper {
            overflow-x: scroll;
            width: 100%;

            @include media-breakpoint-down(md) {
                table {
                    min-width: 600px;
                }
            }
        }

        .article__image {
            display: flex;
            justify-content: center;
            max-height: 240px;
            margin: 0 auto;
        }

        .article__image,
        .article__body {
            margin-top: 15px;
        }

        .article__body {
            img {
                display: block;
                margin: 0 auto;
            }

            > p:first-of-type {
                font-size: var(--font-size-intro);
                line-height: var(--line-height-intro);
            }

            a {
                display: block;
            }
        }

        .article__inner__footer {
            display: flex;
            flex-direction: column;

            .back-wrapper {
                font-family: $font-family-secondary;
                color: color(metallic-bronze);

                a {
                    text-decoration: none;
                }

                .btn {
                    margin-top: 0;
                }

                .back-wrapper__label {
                    margin: 20px;
                }
            }
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 10px;
            color: inherit;
        }

        p,
        ul,
        ol,
        table,
        blockquote {
            + h2,
            + h3,
            + h4,
            + h5,
            + h6 {
                margin-top: 45px;

                @include media-breakpoint-up(xl) {
                    margin-top: 50px;
                }

                @include media-breakpoint-up(xxl) {
                    margin-top: 60px;
                }
            }
        }

        p,
        ul,
        ol,
        table {
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: inherit;
        }

        ul li {
            font-weight: 400;
        }

        @include media-breakpoint-down(lg) {
            article {
                .written-by {
                    margin-top: 25px;
                }
            }

            .article__inner__footer {
                .back-wrapper {
                    margin-top: 50px;
                    border-top: 1px solid rgba(color(metallic-bronze), .2);
                    padding: {
                        top: 25px;
                        bottom: 25px;
                    }
                }

                .share {
                    border-top: 1px solid rgba(color(metallic-bronze), .2);
                    padding-top: 40px;
                    padding-bottom: 20px;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            p,
            ul,
            ol,
            table {
                font-size: 1.6rem;
                line-height: 3rem;
            }

            .article__inner {
                padding: {
                    top: 30px;
                    right: 85px;
                    bottom: 40px;
                    left: 85px;
                }
            }

            .article__image {
                width: 200px;
                height: auto;
                max-height: unset;
            }

            .article__inner__footer {
                flex-direction: row;
                justify-content: space-between;
                margin-top: 20px;
                border-top: 1px solid rgba(color(metallic-bronze), .2);
                padding-top: 20px;

                .share {
                    flex-direction: row;
                }
            }
        }

        @include media-breakpoint-up(xxl) {
            .article__inner {
                padding: {
                    top: 40px;
                    right: 120px;
                    bottom: 65px;
                    left: 120px;
                }
            }

            .author {
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }

        @include media-breakpoint-up(fhd) {
            .article__inner {
                padding: {
                    bottom: 30px;
                }
            }
        }
    }
}
