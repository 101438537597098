.images {
    position: relative;

    .images__image-wrapper {
        padding: 5px;
        position: relative;
        transform: scale(1.2) translateX(30px);

        &::before {
            @include fill-w-h;
            background-color: color(milk);
            clip-path: url('#wobbly-box');
            content: '';
        }

        .inner {
            @include responsive-ratio(567, 450);
            position: relative;
        }

        .images__image:not(.images__image--fruit) {
            @include fill-w-h;
            object-fit: cover;
            clip-path: url('#wobbly-box');
        }

        &:first-child {
            transform: scale(1.2) translateX(30px);
        }

        &.images__image-wrapper--right {
            transform: scale(1.2) translate(-25px, -100px);
        }
    }

    .images__image {
        .st0 {
            html.contrast-mode-high &,
            html.contrast-mode-max & {
                fill: color(cola);
            }
        }

        &.images__image--tl {
            max-height: 220px;
            transform: scaleX(-1) translateX(117px);
        }

        &.images__image--fruit {
            max-width: 85px;
            filter: drop-shadow(5px 7px 4px rgba(0, 0, 0, .3));
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(-30%, 50%);
        }

        &.images__image--animated-lines-top {
            width: 25px;
            position: absolute;
            top: auto;
            right: 10px;
            bottom: 86px;
            transform: rotate(150deg);
        }

        &.images__image--animated {
            width: 70%;
            position: absolute;
            transform: translate(270px, -385px);

            &.animation--active {
                path#poot {
                    transform-origin: 44% 51%;
                    animation: throw;
                    animation-duration: var(--speed-sluggish);
                    animation-fill-mode: forwards;
                }

                g#snoepje {
                    transform-box: fill-box;
                    transform-origin: center;
                    animation: fall-down;
                    animation-duration: .8s;
                    animation-timing-function: linear;
                    animation-delay: .1s;
                    animation-fill-mode: forwards;
                }
            }
        }
    }

    > svg {
        display: block;
    }

    @keyframes fall-down {
        0% {
            transform: translateY(-100px) rotate(0);
        }

        5% {
            transform: translateY(-98px);
        }

        10% {
            transform: translateY(-98px);
        }

        15% {
            transform: translateY(-96px);
        }

        20% {
            transform: translateY(-94px);
        }

        25% {
            transform: translateY(-92px);
        }

        30% {
            transform: translateY(-90px);
        }

        100% {
            transform: translateY(135px) rotate(180deg);
        }
    }

    @keyframes throw {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(-11deg);
        }
    }

    @include media-breakpoint-up(sm) {
        .images__image-wrapper {
            transform: scale(1) translateX(30px) !important; /* stylelint-disable-line declaration-no-important */

            &.images__image-wrapper--right {
                transform: scale(1) translate(-13px, -100px) !important; /* stylelint-disable-line declaration-no-important */
            }
        }

        .images__image {
            &.images__image--tl {
                max-height: 240px;
            }

            &.images__image--fruit {
                max-width: 100px;
            }

            &.images__image--animated-lines-top {
                width: 35px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .image__images {
            .images__image {
                &.images__image--tl {
                    transform: translateX(230px);
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        min-height: 400px;
        margin-top: 60px;

        .images__image-wrapper {
            padding: 15px;

            &:first-child {
                transform: scale(1) translate(0, 0) !important; /* stylelint-disable-line declaration-no-important */
            }

            &.images__image-wrapper--right {
                margin-top: 60px;
                transform: scale(1) translateX(30px) !important; /* stylelint-disable-line declaration-no-important */
            }
        }

        .images__image {
            &.images__image--tl {
                max-height: 390px;
                position: absolute;
                top: -320px;
                right: -420px;
                transform: scaleX(1);
            }

            &.images__image--fruit {
                max-width: 140px;
            }

            &.images__image--animated-lines-top {
                width: 50px;
            }

            &.images__image--animated-lines-bottom,
            &.images__image--animated-lines-top {
                top: auto;
                right: auto;

                path#lijn1,
                path#lijn2,
                path#lijn3 {
                    transform-origin: 34% 50%;
                    animation-duration: var(--speed-sluggish);
                    animation-timing-function: step-end;
                    animation-iteration-count: infinite;
                }

                path#lijn1 {
                    animation-name: wobble-animation-1;
                }

                path#lijn2 {
                    animation-name: wobble-animation-2;
                    animation-delay: .3s;
                }

                path#lijn3 {
                    animation-name: wobble-animation-3;
                    animation-delay: .4s;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .images__image {
            &.images__image--animated {
                width: auto;
                max-height: 485px;
                top: -465px;
                right: -995px;
                transform: none;
            }

            &.images__image--animated-lines-bottom {
                width: 50px;
                position: absolute;
                transform: translate(-17px, -40px) rotate(-35deg);
            }

            &.images__image--animated-lines-top {
                right: -60px;
                bottom: -43px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .images__image {
            &.images__image--animated {
                right: -1150px;
            }

            &.images__image--tl {
                max-height: 390px;
                position: absolute;
                top: -320px;
                right: -500px;
                transform: scaleX(1);
            }

            &.images__image--animated-lines-bottom {
                transform: translate(-17px, -40px) rotate(-35deg);
            }
        }
    }

    @include media-breakpoint-up(fhd) {
        .images__image {
            &.images__image--animated {
                height: 625px;
                max-height: unset;
                top: -565px;
                right: -75px;
            }

            &.images__image--animated-lines-bottom {
                transform: translate(-17px, -40px) rotate(-35deg);
            }
        }
    }
}
