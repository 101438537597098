.navbar__collapse {
    .nav-item.nav-item--children {
        position: relative;

        svg {
            display: none;
        }

        &.open {
            button {
                transform: rotate(180deg);
            }

            .nav-item__dropdown {
                opacity: 1;
                visibility: visible;
                height: 100%;

                li {
                    &:last-of-type {
                        padding-bottom: 20px;
                    }
                }
            }
        }

        @include media-breakpoint-down(xl) {
            .nav-item__dropdown {
                opacity: 0;
                visibility: hidden;
                display: block;
                width: 100%;
                height: 0;
                transition: height .25s linear, opacity .35s linear;

                .nav-link {
                    font-size: 1.5em;

                    @include media-breakpoint-up(lg) {
                        font-size: 20px;
                    }
                }
            }

            .nav-item__inner {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 12px;

                button {
                    width: 40px;
                    height: 40px;
                    pointer-events: auto;

                    i {
                        width: 32px;
                        font-size: .5em;
                    }
                }

                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                    gap: 8px;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            .nav-item__inner {
                display: inline-flex;
                align-items: center;
                gap: 4px;
            }

            button {
                font-size: 10px;
                pointer-events: none;
                position: absolute;
                right: -4px;
                transition: transform .25s linear;
            }

            .nav-item__dropdown {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: calc(100% + 20px);
                left: 50%;
                transform: translateX(-50%) translateY(-10px);
                transition: transform var(--speed-slow) var(--cubic-bezier-2), opacity var(--speed-default) var(--cubic-bezier-2);

                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    fill: color(milk);
                }

                .nav-link {
                    min-width: 170px;
                    padding: 15px 10px;
                    font-size: 1.8rem;
                }
            }

            &:hover {
                &::before {
                    width: 100%;
                    height: 20px;
                    content: '';
                    position: absolute;
                    top: 100%;
                }

                button {
                    transform: scale(-1);
                }

                .nav-item__dropdown {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(-50%) translateY(0);
                }
            }
        }
    }

    .nav-link {
        @include contrast(false, false, color, false);
        display: block;
        font: {
            family: $font-family-secondary;
            weight: 500;
        }
        line-height: 60px;
        color: color(metallic-bronze);
        text: {
            decoration: none;
            align: center;
        }
    }

    @media (pointer: coarse) {
        @include overflow(y);
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        padding: {
            top: 110px;
            right: 25px;
            bottom: 20px;
            left: 25px;
        }
        background-color: color(milk);
        position: fixed;
        top: 100%;
        left: 0;
        z-index: 2;
        transition: transform var(--speed-slow) var(--cubic-bezier-2);

        .overflow {
            display: flex;
            flex-direction: column;
            max-width: 520px;
            min-height: 100%;
            margin: 0 auto;
        }

        .navbar__nav {
            flex-grow: 1;
            text-align: center;
        }

        .nav-item {
            opacity: 0;
            transition: opacity var(--speed-default) linear, transform var(--speed-slow) var(--cubic-bezier-2);

            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    transform: translateY(10px * $i) scale(.5, 1.5);
                    transition-delay: 80ms * $i;
                }
            }
        }

        .nav-link {
            width: 100%;
            font-size: calc(30px * var(--font-size-multiplier));
        }

        .nav-collapsed & {
            transform: translateY(-100%);

            .nav-item {
                opacity: 1;
                transform: translateY(0) scale(1) !important; /* stylelint-disable-line declaration-no-important */
            }
        }

        @include media-breakpoint-up(md) {
            .navbar__nav {
                flex-grow: 0;
                margin: {
                    top: auto;
                    bottom: auto;
                }
            }
        }

        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait), only screen and (min-width: 1366px) and (max-height: 1024px) and (orientation: landscape) {
            padding: {
                top: 200px;
                bottom: 150px;
            }
        }
    }

    @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
        padding: {
            right: calc(env(safe-area-inset-right) + 25px);
            left: calc(env(safe-area-inset-left) + 25px);
        }
    }

    @media (hover: hover) and (pointer: fine) {
        @include media-breakpoint-up(lg) {
            margin: 0 auto;

            .navbar__nav {
                display: flex;
                align-items: center;
            }

            .nav-link {
                padding: {
                    right: 20px;
                    left: 20px;
                }
                font-size: 18px;
                position: relative;

                &:not(.nav-link--button) svg {
                    display: none;
                    width: 100%;
                    height: 4px;

                    path {
                        fill: color(metallic-bronze);
                    }
                }

                &.nav-link--button {
                    @include button;
                    margin-top: 0;
                    padding: {
                        right: 20px;
                        left: 20px;
                    }
                    color: color(metallic-bronze);

                    span {
                        display: block;
                        position: relative;
                        z-index: 2;
                    }

                    svg {
                        @include fill-w-h;
                        pointer-events: none;
                        transition: all var(--speed-fast) var(--cubic-bezier-2);

                        path {
                            fill: color(milk);
                        }
                    }

                    html.contrast-mode-high & {
                        color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
                    }

                    .nav-scrolling & {
                        color: color(milk);

                        svg path {
                            @include contrast(false, false, false, fill);
                            fill: color(sea-buckthorn);
                        }

                        html.contrast-mode-avg & {
                            color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
                        }

                        html.contrast-mode-high & {
                            color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
                        }

                        html.contrast-mode-max & {
                            color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .nav-link {
            font-size: 20px;
            line-height: 1 !important; /* stylelint-disable-line declaration-no-important */
            white-space: nowrap !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    @include media-breakpoint-up(fhd) {
        .nav-link {
            font-size: calc(20px * var(--font-size-multiplier));
        }
    }
}
