.section.section--story-of-bear {
    p {
        font-size: 1.5rem;
        line-height: 2.5rem;
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(xl) {
            font-size: 2rem;
            line-height: 3.4rem;
        }
    }
}
