.section.section--hero--video {
    height: 100vh;
    position: relative;

        .hero__video {
            @include fill-w-h;
            object-fit: cover;
        }

    .hero__inner {
        width: 100%;
        position: absolute;
        bottom: 60px;
        z-index: 10;
    }
}