.notification {
    display: none;
    width: 100%;
    color: color(metallic-bronze);
    position: fixed;
    top: 100%;
    left: 0;
    transform: translateY(100px);
    z-index: 101;

    .snackbar-wobble {
        position: absolute;
        top: -6px;

        html.contrast-mode & {
            display: none;
        }

        @include media-breakpoint-up(md) {
            top: -14px;
        }

        @include media-breakpoint-up(xl) {
            top: -22px;
        }
    }

    .notification__title {
        @include contrast(false, false, color, false);
        display: inline-block;
        padding-right: 100px;
        position: relative;

        .grrr {
            width: 100px;
            height: auto;
            position: absolute;
            right: 0;
            bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            position: static;

            .grrr {
                width: 180px;
                top: -70px;
                right: 0;
                left: 0;
                transform: translateX(-50%);
            }
        }
    }

    .form-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 5px;
        margin-bottom: 0;

        label {
            margin-top: 15px;
            margin-right: 15px;
        }
    }

    .notification__btns {
        .btn {
            margin-top: 20px;
            margin-right: 20px;
        }
    }

    .notification__body {
        font-size: 1.4rem;
        line-height: 2.2rem;
    }

    .notification--cookies__btn-necessary-selected {
        padding-top: 8px;

        span {
            font-family: 'Mr_dodo', sans-serif;
        }
    }

    &.notification--cookies {
        display: block;

        &.notification--cookies--unaccepted {
            transform: translateY(-100%);
        }
    }

    @include media-breakpoint-up(md) {
        .notification__body {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }

        .form-group {
            .form-check {
                display: inline-flex;

                ~ .form-check {
                    margin: {
                        top: 0;
                        left: 30px;
                    }
                }
            }
        }

        .notification__btns {
            .btn {
                margin-top: -10px;

                + .btn {
                    margin-top: 10px;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .notification__body {
            font-size: 1.6rem;
            line-height: 3.2rem;
        }

        .form-group {
            .form-check {
                ~ .form-check {
                    margin-left: 45px;
                }
            }
        }
    }
    padding: {
        top: 20px;
        bottom: 30px;
    }
}
