.card-carousel-slider {
    --size-slider: 230px;
    --size-current-index: 55px;
    --font-size-index: 1.4rem;
    --font-size-name: 2.5rem;
    display: flex;
    justify-content: center;
    margin-top: 60px;

    .card-carousel-slider__holder {
        width: var(--size-slider);
    }

    .tns-ovh {
        overflow: visible;
    }

    .slider-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: calc((var(--size-current-index) * -1) / 2);
    }

    .slider-current-index {
        opacity: 0;
        overflow: hidden;
        width: var(--size-current-index);
        height: var(--size-current-index);
        margin-bottom: 15px;
        border-radius: 50%;
        background-color: color(milk);
        clip-path: url('#slider-current-background');
        position: relative;
        transform: scale(1.2);
        transition: opacity var(--speed-default) ease-out, transform var(--speed-slow) var(--cubic-bezier-3);

        &::before {
            @include contrast(false, background, false, false);
            width: calc(100% - 14px);
            height: calc(100% - 14px);
            border-radius: 50%;
            background-color: color(metallic-bronze);
            clip-path: url('#slider-current-background');
            content: '';
            position: absolute;
            top: 7px;
            left: 7px;
            transform: rotate(165deg);
        }

        span {
            @include contrast(false, false, color, false);
            @include center-x-y;
            display: block;
            font: {
                family: $font-family-secondary;
                weight: 500;
                size: var(--font-size-index);
            }
            line-height: 1.2;
        }
    }

    .slider-current-name {
        @include contrast(false, false, color, false);
        display: block;
        font: {
            family: $font-family-secondary;
            weight: 500;
            size: var(--font-size-name);
        }
        line-height: 1.2;
    }

    .slider-current-content {
        margin-top: 10px;

        p {
            @extend .h4;
            display: block;
            margin: 0;
            font: {
                family: $font-family-secondary;
                weight: 500;
            }
            color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
            text-align: center;
        }
    }

    .card--carousel {
        display: inline-block;
    }

    .slider-controls {
        opacity: 0;
        display: flex;
        align-items: flex-start;
        margin-top: 30px;

        button {
            display: block;
            margin-top: -2px;
            padding: 10px 0;
            line-height: 1;

            i {
                font-size: 15px;
            }

            &[disabled] {
                opacity: .6;
            }
        }

        .slider-controls__nav {
            @include contrast(false, false, color, false);
            flex-shrink: 0;
            min-width: 50px;
            margin: {
                right: 15px;
                left: 15px;
            }
            font: {
                family: $font-family-secondary;
                weight: 500;
                size: var(--font-size-index);
            }
            line-height: 1.2;
            color: color(metallic-bronze);
            text-align: center;

            .nav-divider {
                margin: 0 4px;
            }
        }
    }

    .tns-item {
        &,
        figure {
            transition: transform var(--speed-slow) var(--cubic-bezier-2);
        }

        &.tns-slide-active {
            transform: translate3d(0, 0, 0) rotate(0deg);

            figure {
                transform: scale(1);
            }
        }

        &.current-slide-prev-1 {
            transform: translate3d(40px, -10px, 0) rotate(-15deg);
        }

        &.current-slide-next-1 {
            transform: translate3d(-40px, -10px, 0) rotate(15deg);
        }

        &[class*=' current-slide-prev-'],
        &[class*=' current-slide-next-'] {
            figure {
                transform: scale(.6);
            }
        }

        &[class*=' current-slide-prev-'] {
            transform-origin: bottom right;
        }

        &[class*=' current-slide-next-'] {
            transform-origin: bottom left;
        }

        @include media-breakpoint-up(md) {
            &.current-slide-prev-2 {
                transform: translate3d(135px, 30px, 0) rotate(-24deg);
            }

            &.current-slide-prev-3,
            &.current-slide-prev-4 {
                transform: translate3d(240px, 100px, 0) rotate(-32deg);
            }

            &.current-slide-next-2 {
                transform: translate3d(-135px, 30px, 0) rotate(24deg);
            }

            &.current-slide-next-3,
            &.current-slide-next-4 {
                transform: translate3d(-240px, 100px, 0) rotate(32deg);
            }
        }

        @include media-breakpoint-up(xl) {
            &.current-slide-prev-1 {
                transform: translate3d(60px, -10px, 0) rotate(-15deg);
            }

            &.current-slide-prev-2 {
                transform: translate3d(185px, 40px, 0) rotate(-24deg);
            }

            &.current-slide-prev-3,
            &.current-slide-prev-4 {
                transform: translate3d(320px, 120px, 0) rotate(-32deg);
            }

            &.current-slide-next-1 {
                transform: translate3d(-60px, -10px, 0) rotate(15deg);
            }

            &.current-slide-next-2 {
                transform: translate3d(-185px, 40px, 0) rotate(24deg);
            }

            &.current-slide-next-3,
            &.current-slide-next-4 {
                transform: translate3d(-320px, 120px, 0) rotate(32deg);
            }
        }

        @include media-breakpoint-up(xxl) {
            &.current-slide-prev-1 {
                transform: translate3d(80px, 0, 0) rotate(-15deg);
            }

            &.current-slide-prev-2 {
                transform: translate3d(250px, 70px, 0) rotate(-24deg);
            }

            &.current-slide-prev-3 {
                transform: translate3d(430px, 180px, 0) rotate(-32deg);
            }

            &.current-slide-prev-4 {
                transform: translate3d(620px, 330px, 0) rotate(-38deg);
            }

            &.current-slide-next-1 {
                transform: translate3d(-80px, 0, 0) rotate(15deg);
            }

            &.current-slide-next-2 {
                transform: translate3d(-250px, 70px, 0) rotate(24deg);
            }

            &.current-slide-next-3 {
                transform: translate3d(-430px, 180px, 0) rotate(32deg);
            }

            &.current-slide-next-4 {
                transform: translate3d(-620px, 330px, 0) rotate(38deg);
            }
        }
    }

    &.initialized {
        .slider-current-index {
            opacity: 1;
            transform: scale(1);
        }

        .slider-controls {
            opacity: 1;
        }
    }

    @include media-breakpoint-up(md) {
        --size-slider: 265px;
    }

    @include media-breakpoint-up(lg) {
        --size-current-index: 75px;
        --font-size-index: 2.4rem;
        --font-size-name: 3.5rem;

        .slider-controls {
            button {
                margin-top: 5px;

                i {
                    font-size: 24px;
                }
            }

            .slider-controls__nav {
                margin: {
                    right: 55px;
                    left: 55px;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        --size-slider: 330px;
        --size-current-index: 85px;
        --font-size-name: 4.5rem;

        .slider-controls {
            .slider-controls__nav {
                min-width: 100px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        --size-slider: 450px;
        --size-current-index: 90px;
        --font-size-index: 2.8rem;
    }
}
