.vertical-alignment-helper {
    display: table;
    width: 100%;
    height: 100%;

    .vertical-align-center {
        display: table-cell;
        vertical-align: middle;
    }
}

.fade {
    opacity: 0;
    transition: opacity var(--speed-fast) linear;

    &.show {
        opacity: 1;
    }
}

.modal-backdrop {
    background-color: color(cola);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9997;

    &.show {
        opacity: .4;
    }
}

.modal-open {
    overflow: hidden;

    .modal {
        @include overflow(y);
    }

    //@include media-breakpoint-down(md) {
    //    height: calc(var(--vh, 1vh) * 100);
    //}
}

.modal {
    --border-width: 5px;
    --dismiss-font-size: 30px;

    @include overflow(y);
    display: none;
    outline: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;

    > svg {
        display: block;
    }

    .modal-dialog {
        width: 100%;
        max-width: 770px;
        margin: 45px auto;
        padding: {
            right: 20px;
            left: 20px;
        }
        filter: drop-shadow(0 0 40px rgba(color(cola), .1));
        position: relative;
    }

    button[data-dismiss='modal'] {
        @include contrast(false, false, color, false);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin: 0;
        border: 0;
        color: color(metallic-bronze);
        position: absolute;
        top: 15px;
        right: 24px;
        z-index: 2;
    }

    .modal-content {
        @include contrast(false, background, color, false);
        padding: 60px 20px;
        background-color: color(milk);
        clip-path: url('#modal-box');
        text-align: center;
        position: relative;

        .modal-content__summary {
            max-width: 540px;
            margin: 0 auto;

            .h2 {
                margin-bottom: 20px;
            }

            p {
                @include media-breakpoint-up(lg) {
                    font-size: 2rem;
                    line-height: 3.4rem;
                }
            }
        }
    }

    &.fade {
        .modal-dialog {
            transform: translate(0, -100px);
            transition: transform var(--speed-default) var(--cubic-bezier-2);
        }
    }

    &.show {
        .modal-dialog {
            transform: translate(0, 0);
        }
    }

    @include media-breakpoint-down(lg) {
        .modal-dialog {
            max-width: 100vw;
        }
    }

    @include media-breakpoint-up(lg) {
        .modal-dialog {
            margin: 30px auto;
            padding: 0;
        }

        .modal-content {
            padding: {
                right: 60px;
                left: 60px;
            }
        }
    }
}
