*:focus {
    outline: none;

    html[lang='en_US'] & {
        @include focus;
    }
}

button {
    border: 0;
}

body {
    @include contrast(false, background, color, false);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: color(blacksqueeze);

    main {
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;

        > *:first-child {
            padding-top: calc(var(--pt-section) + var(--height-navbar));
        }

        @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
            padding: {
                right: env(safe-area-inset-right);
                left: env(safe-area-inset-left);
            }
        }
    }

    &.home-page,
    &.about-bear {
        background-color: color(burnt-sienna);
    }
}

.container {
    padding: {
        right: var(--width-grid-gutter);
        left: var(--width-grid-gutter);
    }
    position: relative;
}

.row {
    margin-right: calc(-1 * (var(--width-grid-gutter)) / 2);
    margin-left: calc(-1 * (var(--width-grid-gutter)) / 2);
}

[class*='col-'] {
    padding-right: calc(var(--width-grid-gutter) / 2);
    padding-left: calc(var(--width-grid-gutter) / 2);
}

.collapse {
    display: none;

    &.show {
        display: block;
    }
}

.collapsing {
    overflow: hidden;
    max-height: 0;
    transition: max-height var(--speed-default) var(--cubic-bezier-2); /* stylelint-disable-line plugin/no-low-performance-animation-properties */
    will-change: max-height;
}

.fade {
    opacity: 0;
    transition: opacity var(--speed-fast) linear;

    &.show {
        opacity: 1;
    }
}

.scroll-next-section {
    @include center-x(top, 0);
    width: 0;
    height: 0;
    border-top: 18px solid color(blacksqueeze);
    border-right: 38px solid transparent;
    border-left: 38px solid transparent;
    position: absolute;

    .bg-blacksqueeze & {
        border-top-color: color(milk);
    }

    @include media-breakpoint-up(lg) {
        border-top-width: 30px;
        border-right-width: 60px;
        border-left-width: 60px;
    }
}

.text-center {
    text-align: center;
}

.position-relative {
    position: relative;
}

@include media-breakpoint-only(xs) {
    .order-mobile-1 {
        order: 1;
    }

    .order-mobile-2 {
        order: 2;
    }
}

[class^='icon-minus'] {
    display: block;
    margin-top: 10px;
    font-size: 5px;
    position: absolute;
}

.icon-minus--45 {
    transform: rotate(-45deg);
}

.icon-minus--135 {
    transform: rotate(45deg);
}

img {
    max-width: 100%;
    height: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.wobbly-lines,
.animated-lines {
    @include wobbly-lines-styling;
}

.skip-to-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--height-navbar);
    position: fixed;
    bottom: 100%;
    left: 0;
    transform: translateY(0);
    z-index: 9997;
    transition: transform var(--speed-default) ease-out;

    span {
        @include heading(h4);
        margin-bottom: 0;
    }

    .backdrop {
        width: 300%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        path {
            fill: color(milk);
        }
    }

    &:focus {
        outline: 0;
        transform: translateY(100%);
    }
}
