.section {
    padding: {
        top: var(--pt-section);
        bottom: var(--pb-section);
    }
    background-color: transparent;
    position: relative;

    .section__title {
        margin-bottom: var(--mb-section-title);
        font-size: var(--font-size-section-title);
        line-height: var(--font-size-section-title);
        position: relative;

        label {
            font-size: 2.5rem;
            color: rgb(0, 126, 168, .5);
            position: absolute;
            transform: translate(10px, -15px);
        }

        &.section__title--center {
            text-align: center;
        }

        &.section__title--big {
            margin-bottom: 50px;
            font-size: 5rem;
        }
    }

    .section__body {
        margin-bottom: 40px;
    }

    .section__intro {
        font-size: var(--font-size-section-intro);
        line-height: var(--font-size-section-intro);
    }

    @include media-breakpoint-down(md) {
        .section__title {
            label {
                font-size: 2rem;
            }

            &.section__title--big {
                margin-bottom: 25px;
                font-size: 2.6rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .section__body {
            text-align: center;
        }
    }
}
