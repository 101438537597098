.navbar__toggler {
    width: 55px;
    height: 55px;
    margin-left: auto;
    position: relative;
    z-index: 3;

    .navbar__toggler__background {
        width: 100%;
        height: 100%;

        path {
            fill: color(milk);
        }

        .nav-collapsing & {
            animation: {
                name: wobble;
                duration: var(--speed-sluggish);
                fill-mode: both;
            }

            @keyframes wobble {
                25% {
                    transform: scale(.85, 1.15);
                }

                50% {
                    transform: scale(1.15, .85);
                }

                75% {
                    transform: scale(.9, 1.1);
                }
            }
        }
    }

    .navbar__toggler__hamburger {
        @include center-x-y;
        width: 50px;

        .toggler-line {
            fill: color(metallic-bronze);
            transform-origin: center;

            &.toggler-line--top,
            &.toggler-line--bottom {
                transition: transform var(--speed-slow) var(--cubic-bezier-2);
            }

            &.toggler-line--middle {
                transition: opacity var(--speed-default) linear, transform var(--speed-default) var(--cubic-bezier-2);
            }

            .nav-collapsed & {
                &.toggler-line--top {
                    transform: rotate(-45deg) translateY(16px);
                }

                &.toggler-line--middle {
                    opacity: 0;
                    transform: translateX(-20px);
                }

                &.toggler-line--bottom {
                    transform: rotate(45deg) translateY(-16px);
                }
            }
        }
    }

    .nav-scrolling &,
    .nav-collapsed & {
        .navbar__toggler__background {
            path {
                fill: color(metallic-bronze);
            }
        }

        .navbar__toggler__hamburger {
            .toggler-line {
                fill: color(milk);
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}
