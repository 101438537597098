$icomoon-font-family: "bear" !default;
$icomoon-font-path: "fonts" !default;

$icon-marker-o-fill: "\e91b";
$icon-settings: "\e91a";
$icon-arrow-down: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-right: "\e902";
$icon-arrow-up: "\e903";
$icon-bend-arrow-left: "\e904";
$icon-bend-arrow-right: "\e905";
$icon-check: "\e906";
$icon-chevron-down: "\e907";
$icon-chevron-left: "\e908";
$icon-chevron-right: "\e909";
$icon-chevron-up: "\e90a";
$icon-circle-arrow-left: "\e90b";
$icon-circle-arrow-right: "\e90c";
$icon-envelope: "\e90d";
$icon-facebook: "\e90e";
$icon-instagram: "\e90f";
$icon-linkedin: "\e910";
$icon-menu: "\e911";
$icon-minus: "\e912";
$icon-plus-alt: "\e913";
$icon-plus: "\e914";
$icon-search: "\e915";
$icon-switch-arrow: "\e916";
$icon-twitter: "\e917";
$icon-whtasapp: "\e918";
$icon-youtube: "\e919";

