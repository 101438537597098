.dotted-line {
    .paths {
        fill: none;
        stroke: color(milk);
        stroke-linejoin: round;

        html.contrast-mode-avg & {
            stroke: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-high & {
            stroke: color(milk) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-max & {
            stroke: color(cola) !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    .mask {
        fill: none;
        stroke: color(milk);
        stroke-width: 10;
        stroke-dasharray: 876;
        stroke-dashoffset: 876;
    }

    &.animation--active {
        .mask {
            animation: dash 5s linear alternate;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
        }
    }

    &.dotted-line--visible {
        .mask {
            stroke-dashoffset: 0;
        }
    }

    @keyframes dash {
        from {
            stroke-dashoffset: 876;
        }

        to {
            stroke-dashoffset: 0;
        }
    }
}
