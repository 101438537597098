.card-collection.card-collection--around-the-world {
    .card-collection__images {
        display: flex;

        .card-collection__image-wrapper {
            display: flex;
            flex: 0 1 33%;
            justify-content: center;
            align-items: center;
            position: relative;

            &.card-collection__image-wrapper--spacing-top {
                margin-top: 20px;
            }

            &:last-child {
                .card-collection__image {
                    max-height: 112px;
                }
            }
        }

        .card-collection__image {
            max-height: 150px;

            &.card-collection__image--fruit {
                max-height: 50px;
                position: absolute;
                bottom: -10px;
                left: 0;
                transform: scaleX(-1);
            }

            &.card-collection__image--alt {
                max-height: 130px;
                transform: rotate(5deg) translateY(20px);
            }
        }

        .card-collection__divider {
            font-size: 1.8rem;
            color: rgba(color(metallic-bronze), .8);
            align-self: center;
            transform: translateY(15px);
        }
    }

    .card-collection__plane {
        max-width: 175px;
        position: absolute;
        top: 82%;
        right: -30px;
        transform: translateY(50%);
        z-index: 2;
    }

    @keyframes fly-in {
        0% { transform: scale(.5) translate(350px, -300px); }
        100% { transform: scale(1) translate(0, 0); }
    }

    @keyframes propeller {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }

    @keyframes hovering {
        0% { transform: translateY(0); }
        33% { transform: translateY(3px); }
        66% { transform: translateY(-3px); }
        100% { transform: translateY(0); }
    }

    @include media-breakpoint-up(sm) {
        .card-collection__images {
            .card-collection__image-wrapper {
                .card-collection__image {
                    &.card-collection__image--fruit {
                        left: 15px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .card-collection__images {
            .card-collection__image-wrapper {
                .card-collection__image {
                    max-height: 250px;

                    &.card-collection__image--fruit {
                        max-height: 85px;
                        bottom: -25px;
                        left: -10px;
                    }

                    &.card-collection__image--alt {
                        max-height: 210px;
                        transform: rotate(10deg) translateY(45px);
                    }
                }

                &:last-child {
                    .card-collection__image {
                        max-height: 205px;
                    }
                }
            }

            .card-collection__divider {
                font-size: 2.5rem;
            }
        }

        .card-collection__plane {
            top: 100%;
        }
    }

    @include media-breakpoint-up(lg) {
        .card-collection__images {
            .card-collection__image-wrapper {
                .card-collection__image {
                    &.card-collection__image--fruit {
                        left: 15px;
                    }
                }

                &:last-child {
                    .card-collection__image {
                        max-height: 220px;
                    }
                }
            }

            .card-collection__divider {
                transform: translateY(30px);
            }
        }

        .card-collection__plane {
            max-width: 300px;
            right: 65px;
            animation: fly-in 1s var(--cubic-bezier-2) 0s 1 forwards, hovering 2s var(--cubic-bezier-2) 1s infinite forwards;

            g#propellor {
                transform-origin: 52% 51%;
                transform-box: fill-box;
                animation: {
                    name: propeller;
                    duration: .5s;
                    timing-function: linear;
                    iteration-count: infinite;
                    fill-mode: both;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .card-collection__images {
            .card-collection__image-wrapper {
                .card-collection__image {
                    max-height: 350px;

                    &.card-collection__image--fruit {
                        max-height: 110px;
                        bottom: -20px;
                        left: -22px;
                    }

                    &.card-collection__image--alt {
                        max-height: 290px;
                        margin-top: 15px;
                    }
                }

                &:last-child {
                    .card-collection__image {
                        max-height: 260px;
                    }
                }
            }

            .card-collection__divider {
                font-size: 3.7rem;
            }
        }

        .card-collection__plane {
            max-width: 300px;
            right: 65px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .card-collection__images {
            .card-collection__image-wrapper {
                .card-collection__image {
                    max-height: 405px;

                    &.card-collection__image--fruit {
                        bottom: -10px;
                        left: -5px;
                    }

                    &.card-collection__image--alt {
                        max-height: 350px;
                    }
                }

                &:last-child {
                    .card-collection__image {
                        max-height: 330px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(fhd) {
        .card-collection__images {
            .card-collection__image-wrapper {
                .card-collection__image {
                    max-height: 470px;

                    &.card-collection__image--fruit {
                        bottom: 30px;
                        left: -5px;
                    }

                    &.card-collection__image--alt {
                        max-height: 380px;
                    }
                }

                &:last-child {
                    .card-collection__image {
                        max-height: 350px;
                    }
                }
            }
        }

        .card-collection__plane {
            max-width: 430px;
            right: 115px;
        }
    }
}
