.section.section--form {
    .section__intro {
        margin-bottom: 20px;
    }

    form {
        .form-footer {
            display: flex;
            justify-content: center;
        }
    }

    @include media-breakpoint-up(lg) {
        .section__intro {
            margin-bottom: 25px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .section__intro {
            margin-bottom: 40px;
        }
    }
}