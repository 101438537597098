.navbar__brand {
    position: relative;
    z-index: 3;

    .bear-logo {
        width: 110px;

        .bear-logo-fill {
            html.contrast-mode-high &,
            html.contrast-mode-max & {
                fill: color(cola);
            }
        }
    }

    @include media-breakpoint-up(xl) {
        margin-right: 28px;

        .bear-logo {
            width: 132px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .bear-logo {
            width: 154px;
        }
    }
}
