.validation {
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    top: calc(var(--size-input) / 2);
    right: 15px;
    transform: translateY(-50%);
}

.form-group {
    &.form-group--valid,
    &.form-group--invalid {
        .validation {
            display: block;

            i {
                &::after {
                    @extend %icon;
                    content: $icon-check;
                }
            }
        }

        .form-control {
            padding-right: 45px;
        }
    }

    &.form-group--invalid {
        color: color(burnt-sienna);

        .form-control,
        .custom-select {
            color: color(burnt-sienna);
        }

        .validation {
            font-size: 22px;
            transform: translateY(-50%) rotate(45deg);

            i {
                &::after {
                    content: $icon-plus-alt;
                }
            }
        }

        .custom-select {
            .validation {
                margin: 0;
                top: 15px;
                right: 45px;
            }
        }

        html.contrast-mode & {
            color: color(guardsman-red);
        }
    }

    &.form-group--valid {
        color: color(metallic-bronze);

        .form-control {
            color: color(metallic-bronze);
        }

        i {
            color: color(apple);
        }

        html.contrast-mode & {
            color: color(japanese-laurel);
        }
    }
}
