.animate {
    &.animate--fade {
        visibility: hidden;
    }

    &.animation--active {
        &.animate--fade {
            visibility: visible;
            animation: fade-in var(--speed-fast) linear alternate;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes rotate-in {
    0% {
        transform: rotate(-270deg) scale(0);
    }

    70% {
        transform: rotate(10deg);
    }

    100% {
        transform: rotate(0deg) scale(1);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes wobble-animation-1 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(8deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes wobble-animation-2 {
    0% {
        transform: rotate(8deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(8deg);
    }
}

@keyframes wobble-animation-3 {
    0% {
        transform: rotate(-8deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-8deg);
    }
}

@keyframes rotate-in {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(-15deg);
    }
}

@keyframes fly-in {
    0% { transform: scale(.5) translate(350px, -300px); }
    100% { transform: scale(1) translate(0, 0); }
}

@keyframes text-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
