.product {
    text-decoration: none;

    .product__image {
        padding-bottom: 100%;
        position: relative;

        img {
            @include fill-w-h;
            object-fit: contain;
        }

        .animated-lines {
            top: 0;
            left: 0;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 66.666666%;
        }
    }

    .product__title {
        font: {
            family: $font-family-tertiary;
            size: 4.5rem;
            weight: 700;
        };
        color: color(milk);
    }

    .product__description {
        max-width: 185px;
        margin: 20px auto 0 auto;
        font: {
            family: $font-family-secondary;
            size: 2.2rem;
            weight: 500;
        };
        line-height: 2.4rem;

        &.product__description--fw {
            max-width: 100%;
        }

        &.product__description--one-line {
            max-width: unset;
        }

        html.font-resize & {
            padding: 0 !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    &.product--no-padding {
        padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    &.product--center {
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        .animated-lines {
            @include wobbly-lines-animation;
        }
    }
}
