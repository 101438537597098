.custom-control {
    display: flex;
    margin-top: 5px;
    position: relative;

    > * {
        cursor: pointer;
    }

    .custom-control__indicator {
        flex-shrink: 0;
        width: var(--size-custom-control-indicator);
        height: var(--size-custom-control-indicator);
        border-radius: 5px;
        background-color: color(milk);
        position: relative;
        top: calc((var(--line-height-base) - var(--size-custom-control-indicator)) / 2);

        .icon-check {
            opacity: 0;
        }

        html.contrast-mode-avg & {
            background-color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-high & {
            background-color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-max & {
            background-color: color(cola) !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    .custom-control__text {
        padding-left: 10px;
        font: {
            weight: 400;
            size: 1.4rem;
        }
        user-select: none;

        p {
            font-size: inherit;
            line-height: inherit;
            color: inherit;
        }

        a {
            text-decoration: underline;
            white-space: nowrap;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
        }
    }

    input[type='checkbox'],
    input[type='radio'],
    .custom-control__input {
        @include fill-w-h;
        opacity: 0;
        z-index: -1;

        &:disabled {
            ~ * {
                opacity: .6;
            }
        }

        &:focus {
            ~ .custom-control__indicator {
                @include focus;
            }
        }
    }

    &.custom-control--checkbox {
        .custom-control__input {
            &:checked {
                + .custom-control__indicator {
                    .icon-check {
                        opacity: 1;
                    }
                }
            }

            + .custom-control__indicator {
                .icon-check {
                    @include center-x-y();
                    opacity: 0;
                    font-size: 10px;
                    color: color(apple);
                }
            }
        }
    }

    &.custom-control--radio {
        .custom-control__indicator {
            border-radius: 50%;

            &::after {
                display: block;
                width: 12px;
                height: 12px;
                margin: {
                    top: -6px;
                    left: -6px;
                }
                border-radius: 50%;
                background-color: color(apple);
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: scale(0);
                transition: transform var(--speed-default) var(--cubic-bezier-2);
            }
        }

        .custom-control__input {
            &:checked {
                + .custom-control__indicator {
                    &::after {
                        transform: scale(1);
                    }
                }

                ~ * {
                    cursor: default;
                }
            }
        }

        &.custom-control--radio--alt {
            .custom-control__indicator {
                background-color: color(milk);

                &::after {
                    background-color: color(apple);
                }
            }
        }
    }

    &.custom-control--cookie {
        .custom-control__indicator {
            border-radius: 5px;
            box-shadow: 0 0 0 2px color(metallic-bronze);

            .icon-check {
                font-size: 11px;
            }
        }

        .custom-control__text {
            font: {
                family: $font-family-secondary;
            }
        }
    }

    .form-group--inline & {
        display: inline-flex;

        ~ .custom-control {
            margin: {
                top: 0;
                left: 15px;
            }
        }
    }
}
