.card.card--carousel {
    figure {
        overflow: hidden;
        border-radius: 35px;
        box-shadow: 0 3px 18px 0 rgba(color(cola), .2);
        position: relative;

        img {
            width: 100%;
        }
    }

    .card__label,
    .card__content {
        display: none;
    }

    .card__content {
        @extend .h4;
        font: {
            family: $font-family-secondary;
            weight: 500;
        }
        color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
        text-align: center;
    }

    &.card--carousel--current {
        z-index: 2;
    }
}
