.btn {
    @include button;

    i {
        font-size: var(--font-size-base-px);

        &[class^='icon-minus'] {
            margin-top: 0;
            font-size: 5px;
        }
    }

    &:disabled {
        opacity: .3;
        pointer-events: none;
    }

    &:not(.btn--text):not(.btn--io) svg {
        @include fill-w-h;
        pointer-events: none;
        transition: all var(--speed-fast) var(--cubic-bezier-2);
    }

    &.btn--ir {
        i {
            margin-left: 10px;
        }
    }

    &.btn--il {
        i {
            margin-right: 10px;
        }
    }

    &.btn--io {
        width: var(--size-button-circle);
        height: var(--size-button-circle);
        min-height: inherit;
        border-radius: 100%;
        padding: 0;
        font-size: 1.4rem;

        i {
            pointer-events: none;

            &[class*='icon-arrow'] {
                font-size: 14px;
            }
        }

        span {
            @include fill-w-h;
            z-index: 0;
            transition: transform var(--speed-default) ease-in-out;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &.btn--big {
            height: 50px;
            font-size: 1.4rem;
        }
    }

    &.btn--o {
        border: 2px solid color(metallic-bronze);
    }

    &.btn--fwm {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    &.btn--center-y {
        @include center-y(auto, 15px);
        margin-top: 0;
        z-index: 2;
    }

    &.btn--text {
        height: auto;
        padding: 10px 0;
        font-size: 1.4rem;

        svg {
            width: 100%;
            height: 4px;
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
        }

        &:hover {
            text-decoration: none;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.7rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 2rem;
        }
    }

    &.btn--prev,
    &.btn--next {
        top: calc(var(--height-navbar) + 300px);
    }

    &.btn--no-spacing-top {
        max-height: 55px;
        margin-top: 0;
        margin-left: 30px;
    }

    @each $name, $value in $colors {
        &.btn--#{$name} {
            @include contrast(false, false, color, false);
            color: $value;

            > * {
                color: inherit;
            }

            &.btn--text,
            &.btn--io {
                path {
                    @include contrast(false, false, false, fill);
                    fill: $value;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &.btn--small-mobile {
            min-width: inherit;
            margin-top: 5px;
        }

        &.btn--smaller-mobile {
            width: auto;
            min-width: inherit;
            margin-left: 0;
        }
    }

    @include media-breakpoint-down(md) {
        &.btn--smaller-mobile {
            width: auto;
            min-width: inherit;
            margin-left: 0;
        }
    }
}
