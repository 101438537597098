.section.section--cardcollection-carousel {
    padding: 0;

    .section__inner {
        overflow: hidden;
        padding: {
            top: var(--pt-section);
            bottom: var(--pb-section);
        }
    }

    .button-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .btn {
            margin: {
                right: 5px;
                left: 5px;
            }
        }
    }
}