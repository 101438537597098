.font-resizer {
    @include contrast(border, false, color, false);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid color(metallic-bronze);
    border-radius: 30px;

    .font-resizer__btn {
        width: 65px;
        height: 55px;
        position: relative;
        transition: opacity var(--speed-fast) ease-out, transform var(--speed-fast) ease-out;

        i {
            @include center-x-y;
        }

        .icon-minus {
            margin: 0;
            font-size: 4px;
        }

        &:active {
            transform: scale(1.2);
        }

        &[disabled] {
            opacity: .5;
        }

        &.font-resizer__btn--bigger {
            &::after {
                width: 2px;
                height: 15px;
            }
        }

        html.contrast-mode-avg & {
            i {
                color: color(metallic-bronze);
            }
        }

        html.contrast-mode-high & {
            i {
                color: color(milk);
            }
        }

        html.contrast-mode-max & {
            i {
                color: color(cola);
            }
        }
    }

    .font-resizer__sizes {
        display: flex;
        align-items: baseline;
        font-family: $font-family-secondary;
        line-height: 1;
        letter-spacing: 1.7px;

        span {
            &:nth-child(1) {
                font-size: 12px;
            }

            &:nth-child(2) {
                font-size: 16px;
            }

            &:nth-child(3) {
                font-size: 21px;
            }
        }
    }
}
