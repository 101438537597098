$icomoon-font-path: $font-path;

@import '../../fonts/bear-icons/style';
@import '../../fonts/bear-icons/variables';

%icon {
    speak: none;

    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important; /* stylelint-disable-line declaration-no-important */
    font-weight: 400;
    font-size: var(--font-size-base-px);
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cross {
    width: 18px;
    height: 18px;
    font-size: 4px;
    position: relative;
    transform: rotate(45deg);

    &::before,
    &::after {
        display: block;
        content: $icon-minus;
        position: absolute;
        top: 50%;
        left: 50%;
    }

    &::before {
        transform: translate(-50%, -50%);
    }

    &::after {
        transform: rotate(90deg) translate(-50%, -50%);
        transform-origin: 0 0;
    }

    @include media-breakpoint-up(lg) {
        width: 25px;
        height: 25px;
        font-size: 5px;
    }
}
