.nav-collapsed {
    @include media-breakpoint-down(md) {
        overflow: hidden;

        main {
            overflow: hidden;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .site-footer {
            position: fixed;
            top: 110%;
            right: 0;
            left: 0;
        }
    }
}

.navbar {
    width: 100%;
    height: var(--height-navbar);
    padding-top: 12px;
    line-height: var(--line-height-base-px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9996;

    .navbar__inner {
        display: flex;
        align-items: center;
        padding: 0 25px;
    }

    .navbar__backdrop {
        opacity: 0;
        width: 300%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        path {
            fill: color(milk);

            html.contrast-mode-avg & {
                fill: color(milk) !important; /* stylelint-disable-line declaration-no-important */
            }

            html.contrast-mode-high & {
                fill: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
            }

            html.contrast-mode-max & {
                fill: color(banana) !important; /* stylelint-disable-line declaration-no-important */
            }
        }

        html.contrast-mode-avg & {
            color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-high & {
            color: color(milk) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-max & {
            color: color(banana) !important; /* stylelint-disable-line declaration-no-important */
        }

        .nav-scrolling & {
            opacity: 1;
        }
    }

    @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
        padding: {
            right: env(safe-area-inset-right);
            left: env(safe-area-inset-left);
        }
    }

    @include media-breakpoint-down(md) {
        .navbar__backdrop {
            width: 200%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    @include media-breakpoint-up(xl) {
        .navbar__inner {
            padding: {
                right: 45px;
                left: 45px;
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        @include media-breakpoint-up(lg) {
            .navbar__holder {
                display: flex;
                flex-grow: 1;
                align-items: center;
                position: relative;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .navbar__inner {
            @each $breakpoint, $container-max-width in $container-max-widths {
                @if $breakpoint == 'xxl' or $breakpoint == 'fhd' {
                    @include media-breakpoint-up($breakpoint) {
                        max-width: $container-max-width;
                    }
                }
            }
            margin: 0 auto;
            padding: {
                right: var(--width-grid-gutter);
                left: var(--width-grid-gutter);
            }
        }
    }
}

.navbar__settings {
    display: none;

    @media (hover: hover) and (pointer: fine) {
        @include media-breakpoint-up(lg) {
            margin-left: 30px;
        }
    }
}

@import 'brand';
@import 'toggler';
@import 'navigation';
@import 'search';
@import 'language-switcher';
