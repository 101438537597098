// Center Horizontally
@mixin center-x($position, $distance) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @if $position == 'top' {
        top: $distance;
        bottom: auto;
    }

    @else {
        top: auto;
        bottom: $distance;
    }
}

// Center Vertically
@mixin center-y($position, $distance) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @if $position == 'left' {
        right: auto;
        left: $distance;
    }

    @else {
        right: $distance;
        left: auto;
    }
}

// Center both Horizontally and Vertically
@mixin center-x-y {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Fill both Width and Height
@mixin fill-w-h {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

// Overflow
@mixin overflow($axis) {
    @if $axis == 'x' {
        overflow-x: auto;
        overflow-y: hidden;
    }

    @else {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

// Content animation
@mixin animation-content($duration) {
    animation: {
        name: content;
        direction: normal;
        timing-function: ease-in-out;
        iteration-count: 1;
    }

    @if $duration {
        animation-duration: $duration;
    }

    @else {
        animation-duration: .3s;
    }

    @keyframes content {
        from {
            opacity: 0;
            transform: translateY(5%);
        }

        to {
            opacity: 1;
            transform: translateY(0%);
        }
    }
}

// Headings
@mixin heading($heading) {
    margin-bottom: 15px;
    font-family: $font-family-secondary;
    font-weight: 500;
    color: color(metallic-bronze);

    @if $heading == 'h1' {
        font-size: 3.5rem;
        line-height: 1;

        @include media-breakpoint-up(lg) {
            font-size: 5.5rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 7rem;
        }
    }

    @if $heading == 'h2' {
        font-size: 3rem;
        line-height: 1;

        @include media-breakpoint-up(lg) {
            font-size: 5rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 6rem;
        }
    }

    @if $heading == 'h3' {
        font-size: 2.5rem;
        line-height: 1;

        @include media-breakpoint-up(lg) {
            font-size: 3.5rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 4.5rem;
        }
    }

    @if $heading == 'h4' {
        font-size: 2rem;
        line-height: 2.4rem;

        @include media-breakpoint-up(xl) {
            font-size: 2.4rem;
            line-height: 2.8rem;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 2.8rem;
            line-height: 3.4rem;
        }
    }

    @if $heading == 'h5' {
        font-size: 1.6rem;
        line-height: 2rem;

        @include media-breakpoint-up(xl) {
            font-size: 1.8rem;
            line-height: 3.2rem;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 2.2rem;
            line-height: 2.4rem;
        }
    }

    @if $heading == 'h6' {
        font-family: $font-family-base;
        font-size: var(--font-size-base);
        line-height: var(--line-height-base);
    }
}

// Button
@mixin button {
    overflow: visible;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: var(--height-btn);
    margin: {
        top: 15px;
        right: 0;
        bottom: 0;
        left: 0;
    }
    padding: 15px 30px;
    font: {
        family: $font-family-secondary;
        weight: 500;
        size: 1.8rem;
    }
    line-height: 1;
    color: color(milk);
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    transition: filter var(--speed-fast) ease-in-out; /* stylelint-disable-line */

    svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -1px;
        left: 0;

        path {
            fill: none;
            transition: fill var(--speed-default) ease-in-out; /* stylelint-disable-line */
        }
    }

    i {
        font-size: var(--font-size-base-px);
    }

    &:not(.btn--io):not(.btn--text) {
        min-width: 170px;
    }

    &:not(.btn--io) {
        span {
            display: block;
            position: relative;
            z-index: 2;
        }
    }
}

// Responsive Ratio
@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote(( $y / $x ) * 100 + '%');

    @if $pseudo {
        &::before {
            display: block;
            width: 100%;
            padding-top: $padding;
            content: '';
            position: relative;
        }
    }

    @else {
        padding-top: $padding;
    }
}

// Truncate
@mixin truncate($truncation-boundary) {
    overflow: hidden;
    max-width: $truncation-boundary;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin wobbly-lines-animation {
    path.lijn1,
    path.lijn2,
    path.lijn3 {
        transform-origin: 34% 50%;
        animation-duration: 1s;
        animation-timing-function: step-end;
        animation-iteration-count: infinite;
    }

    path.lijn1 {
        animation-name: wobble-animation-1;
    }

    path.lijn2 {
        animation-name: wobble-animation-2;
        animation-delay: .3s;
    }

    path.lijn3 {
        animation-name: wobble-animation-3;
        animation-delay: .4s;
    }
}

@mixin wobbly-lines-styling {
    display: block;
    width: 52px;
    margin-right: -10px;
    pointer-events: none;
    position: absolute;
    top: -30px;
    right: 100%;
    transform: rotate(-15deg);

    path {
        @include contrast(false, false, false, fill);
        fill: color(milk);
    }

    .wobbly-lines__line {
        fill: none;
        stroke: color(milk);
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 11px;

        html.contrast-mode-avg & {
            stroke: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-high & {
            stroke: color(milk) !important; /* stylelint-disable-line declaration-no-important */
        }

        html.contrast-mode-max & {
            stroke: color(cola) !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    @include media-breakpoint-up(lg) {
        width: 65px;
        margin-right: -20px;
    }
}

%placeholder {
    font: {
        size: 1.2rem;
        weight: 700;
    }
    padding: 10px;
}

@mixin wobbly-box {
    @include fill-w-h;
    background-color: color(milk);
    clip-path: url('#wobbly-box');
    /* stylelint-disable declaration-no-important */
    html.contrast-mode-avg & {
        background-color: color(metallic-bronze) !important;
        color: color(milk) !important;
    }

    html.contrast-mode-high & {
        background-color: color(milk) !important;
        color: color(metallic-bronze) !important;
    }

    html.contrast-mode-max & {
        background-color: color(cola) !important;
        color: color(milk) !important;
    }
    /* stylelint-enable declaration-no-important */
}

// Contrast
@mixin contrast($border, $background-color, $color, $fill) {
    /* stylelint-disable declaration-no-important */
    html.contrast-mode-avg & {
        @if $border == 'border' {
            border: 1px solid color(metallic-bronze) !important;
        }

        @if $border == 'border-color' {
            border-color: color(metallic-bronze) !important;
        }

        @if $background-color == 'background' {
            background-color: color(milk) !important;
        }

        @if $color == 'color' {
            color: color(metallic-bronze) !important;
        }

        @if $fill == 'fill' {
            fill: color(metallic-bronze) !important;
        }
    }

    html.contrast-mode-high & {
        @if $border == 'border' {
            border: 1px solid color(milk) !important;
        }

        @if $border == 'border-color' {
            border-color: color(milk) !important;
        }

        @if $background-color == 'background' {
            background-color: color(metallic-bronze) !important;
        }

        @if $color == 'color' {
            color: color(milk) !important;
        }

        @if $fill == 'fill' {
            fill: color(milk) !important;
        }
    }

    html.contrast-mode-max & {
        @if $border == 'border' {
            border: 1px solid color(cola) !important;
        }

        @if $border == 'border-color' {
            border-color: color(cola) !important;
        }

        @if $background-color == 'background' {
            background-color: color(banana) !important;
        }

        @if $color == 'color' {
            color: color(cola) !important;
        }

        @if $fill == 'fill' {
            fill: color(cola) !important;
        }
        /* stylelint-enable declaration-no-important */
    }
}

@mixin focus {
    outline-color: color(blueribbon);
    outline-style: dotted;
    outline-width: .2rem;
    outline-offset: 5px;
}