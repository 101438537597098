.section.section--faq {
    --pt-section: 45px;
    min-height: 400px;

    .section--faq__intro {
        margin-bottom: 35px;
    }

    .filter {
        margin-bottom: 60px;
    }

    .results-label {
        margin-bottom: 10px;
        text-align: center;

        span {
            @include contrast(false, false, color, false);
            color: color(metallic-bronze);
        }
    }

    @include media-breakpoint-up(md) {
        --pt-section: 80px;

        .section--faq__intro {
            margin-bottom: 25px;
        }

        .filter {
            margin-top: 35px;
            margin-bottom: 30px;
        }
    }

    @include media-breakpoint-up(lg) {
        --pt-section: 70px;
    }

    @include media-breakpoint-up(xl) {
        --pt-section: 80px;

        .section--faq__intro {
            margin-bottom: 35px;
        }

        .filter {
            margin-top: 40px;
        }

        .results-label {
            margin-bottom: 35px;
        }
    }

    @include media-breakpoint-up(xxl) {
        --pt-section: 45px;

        .section--faq__intro {
            margin-bottom: 45px;
        }

        .filter {
            margin-top: 50px;
        }
    }
}
