.section.section--hero-about {
    --pt-section: 45px;
    --pb-section: 60px;

    @include media-breakpoint-up(md) {
        --pt-section: 60px;
    }

    @include media-breakpoint-up(md) {
        --pt-section: 45px;
        --pb-section: 90px;
    }
}
