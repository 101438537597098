.section.section--card-collection {
    --pt-section: 125px;
    --pb-section: 230px;

    .card-collection {
        position: relative;
    }

    .card-collection__inner {
        text-align: center;
    }

    .card-collection__title {
        margin-bottom: 20px;
    }

    .card-collection__bg-image {
        width: 140px;
        height: auto;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;

        .st0 {
            html.contrast-mode-high &,
            html.contrast-mode-max & {
                fill: color(cola);
            }
        }
    }

    .card-collection__card-set {
        z-index: 5;

        img {
            width: 115px;
            max-width: none;
            border-radius: 10px;
            filter: drop-shadow(0 0 5px rgba(color(cola), .3));
            position: relative;
        }

        &.card-collection__card-set--top-left {
            position: absolute;
            top: -60px;
            right: 100%;
            transform: translateX(calc(100% - 60px));

            img:first-child {
                transform: rotate(32deg);
            }

            img:nth-child(2) {
                margin-top: -30px;
                transform: rotate(15deg);
            }
        }

        &.card-collection__card-set--top-right {
            position: absolute;
            top: -40px;
            left: 100%;
            transform: translateX(calc((100% - 70px) * -1));

            img:nth-child(1) {
                transform: rotate(-17deg);
            }

            img:nth-child(2) {
                margin: {
                    top: -50px;
                    left: 25px;
                }
                transform: rotate(-24deg);
                z-index: -1;
            }
        }

        &.card-collection__card-set--bottom-left {
            position: absolute;
            right: 100%;
            bottom: 80px;
            transform: translateX(calc(100% - 60px));

            img:first-child {
                transform: rotate(-15deg);
                z-index: 2;
            }

            img:nth-child(2) {
                margin-top: -45px;
                transform: rotate(-6deg);
            }

            img:nth-child(3) {
                margin: {
                    top: -25px;
                    left: -20px;
                }
                transform: rotate(32deg);
                z-index: 2;
            }
        }

        &.card-collection__card-set--bottom-right {
            position: absolute;
            bottom: 120px;
            left: 100%;
            transform: translateX(calc((100% - 70px) * -1));

            img:first-child {
                margin-left: 10px;
                transform: rotate(-6deg);
            }

            img:nth-child(2) {
                margin-top: -40px;
                transform: rotate(10deg);
                z-index: 2;
            }

            img:nth-child(3) {
                margin: {
                    top: -30px;
                    left: 20px;
                }
                transform: rotate(-28deg);
            }
        }
    }

    &.section--card-collection-alt {
        --pt-section: 45px;
        --pb-section: 105px;
    }

    @keyframes slide-in-left {
        0% {
            transform: translate(0);
        }

        100% {
            transform: translateX(calc(100% - 60px));
        }
    }

    @keyframes slide-in-right {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(calc((100% - 70px) * -1));
        }
    }

    @include media-breakpoint-down(md) {
        &.section--card-collection--collect {
            .card-collection__inner {
                margin-bottom: 30px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        --pt-section: 140px;
        --pb-section: 170px;

        .card-collection__card-set {
            img {
                width: 155px;
            }

            &.card-collection__card-set--bottom-left {
                bottom: -10px;
                z-index: 7;

                img:first-child {
                    margin-left: -50px;
                    transform: rotate(-8deg);
                    z-index: 4;
                }

                img:nth-child(2) {
                    margin-top: -10px;
                    transform: rotate(5deg);
                    z-index: 3;
                }

                img:nth-child(3) {
                    margin-top: -75px;
                    transform: rotate(-6deg);
                }
            }

            &.card-collection__card-set--bottom-right {
                bottom: 0;
                transform: translateX(calc((100% - 50px) * -1));

                img:nth-child(2) {
                    margin-top: -30px;
                    transform: rotate(10deg);
                }

                img:nth-child(3) {
                    margin: {
                        top: -40px;
                        left: -30px;
                    }
                    transform: rotate(15deg);
                }
            }
        }

        .card-collection__bg-image {
            width: 180px;
            top: 50%;
            left: -10px;
            transform: translateY(-50%);
            z-index: 6;
        }

        &.section--card-collection-alt {
            --pt-section: 115px;
            --pb-section: 140px;
        }
    }

    @include media-breakpoint-up(lg) {
        .card-collection__bg-image {
            width: 220px;
        }

        .card-collection__inner {
            padding: {
                right: 30px;
                left: 30px;
            }
        }

        &.section--card-collection-alt {
            --pt-section: 45px;
            --pb-section: 150px;
        }
    }

    @include media-breakpoint-up(xl) {
        --pt-section: 240px;
        --pb-section: 260px;

        .card-collection__card-set {
            img {
                width: 195px;
            }

            &.card-collection__card-set--top-left,
            &.card-collection__card-set--bottom-left,
            &.card-collection__card-set--top-right,
            &.card-collection__card-set--bottom-right {
                transform: translateX(0);
                animation: {
                    duration: 1.75s;
                    timing-function: var(--cubic-bezier-4);
                    fill-mode: forwards;
                }
            }

            &.card-collection__card-set--top-right {
                animation-delay: .5s;
            }

            &.card-collection__card-set--bottom-left {
                animation-delay: 1s;
            }

            &.card-collection__card-set--bottom-right {
                animation-delay: 1.5s;

                img:nth-child(2) {
                    margin-top: -50px;
                }

                img:nth-child(3) {
                    margin: {
                        top: -80px;
                        left: -50px;
                    }
                }
            }
        }

        .card-collection__images {
            .card-collection__image-wrapper.card-collection__image-wrapper--spacing-top {
                margin-top: 40px;
            }
        }

        .card-collection__bg-image {
            width: 300px;
            left: 20px;
        }

        &.animation--active {
            .card-collection__card-set {
                &.card-collection__card-set--top-right,
                &.card-collection__card-set--bottom-right {
                    animation-name: slide-in-right;
                }

                &.card-collection__card-set--top-left,
                &.card-collection__card-set--bottom-left {
                    animation-name: slide-in-left;
                }
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        --pt-section: 250px;
        --pb-section: 300px;

        .card-collection__card-set {
            img {
                width: 215px;
                border-radius: 20px;
            }
        }

        .card-collection__bg-image {
            width: 340px;
        }

        .card-collection__inner {
            padding: 0;
        }
    }

    @include media-breakpoint-up(fhd) {
        --pt-section: 310px;
        --pb-section: 340px;

        .card-collection__card-set {
            img {
                width: 240px;
            }
        }

        .card-collection__bg-image {
            width: 360px;
            left: 100px;
        }
    }
}
