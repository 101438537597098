.hero {
    .hero__title {
        margin-bottom: 10px;

        &.hero__title--spacing-bottom {
            margin-bottom: 20px;
        }

        &.hero__title--spacing-top {
            margin-top: 20px;
        }

        &.hero__title--big {
            margin-bottom: 0;
            font-size: 4.2rem;
        }
    }

    .hero__inner {
        text-align: center;
        position: relative;

        svg {
            position: absolute;
            top: -5px;
            right: -5px;
            bottom: -5px;
            left: -5px;
        }

        .hero__img {
            margin-bottom: 30px;
        }

        .wobbly-lines {
            display: none;
        }

        p {
            margin-bottom: 40px;
            color: color(milk);
            font: {
                family: $font-family-secondary;
                size: 3.2rem;
                weight: 500;
            }
        }
    }

    .hero__buttons {
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            a:first-child {
                order: 1;
            }
        }
    }

    .hero__img-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
        position: relative;

        .inner {
            @include responsive-ratio(839, 446);
            position: relative;
        }

        .hero__img {
            @include fill-w-h;

            img {
                @include fill-w-h;
            }
        }
    }

    .hero__image-wrapper {
        max-width: 165px;
        position: absolute;
        bottom: -40px;

        .hero__img {
            width: 100%;
            position: relative;
            z-index: 1;
        }

        &.hero__image-wrapper--left {
            left: 10px;

            .hero__img {
                &.hero__img--small {
                    max-height: 20px;
                    position: absolute;
                    top: 15px;
                    left: -70px;
                    transform: rotate(-30deg);
                }

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }

        &.hero__image-wrapper--right {
            right: 10px;

            .hero__img {
                transform: scaleX(-1);

                &.hero__img--small {
                    max-height: 20px;
                    position: absolute;
                    top: 15px;
                    right: -70px;
                    transform: rotate(45deg);
                }
            }

            .wobbly-lines {
                max-width: 50px;
                transform: rotate(26deg) translate(88px, -19px);
            }
        }
    }

    @include media-breakpoint-down(md) {
        .hero__image-wrapper {
            .hero__image-wrapper--right {
                .wobbly-lines {
                    max-width: 38px;
                    transform: rotate(26deg) translate(131px, -23px);
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .hero__img-wrapper {
            width: 60%;
        }
    }

    @include media-breakpoint-up(lg) {
        .hero__image-wrapper {
            max-width: 305px;

            .hero__img--small {
                max-height: 35px !important; /* stylelint-disable-line declaration-no-important */
                top: 30px;
            }

            path#lijn1,
            path#lijn2,
            path#lijn3 {
                transform-origin: 34% 50%;
                animation-duration: 1s;
                animation-timing-function: step-end;
                animation-iteration-count: infinite;
            }

            path#lijn1 {
                animation-name: wobble-animation-1;
            }

            path#lijn2 {
                animation-name: wobble-animation-2;
                animation-delay: .3s;
            }

            path#lijn3 {
                animation-name: wobble-animation-3;
                animation-delay: .4s;
            }

            &.hero__image-wrapper--left {
                .hero__img {
                    &.hero__img--small {
                        top: 30px;
                        left: -125px;
                        transform: rotate(-30deg);
                    }
                }

                .wobbly-lines {
                    max-width: 50px;
                    transform: rotate(-27deg) translate(60px, 60px);
                }
            }

            &.hero__image-wrapper--right {
                .hero__img {
                    &.hero__img--small {
                        top: 35px;
                        right: -125px;
                        transform: rotate(30deg);
                    }
                }

                .wobbly-lines {
                    max-width: 50px;
                    transform: rotate(55deg) translate(232px, -175px);
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .hero__image-wrapper.hero__image-wrapper--left {
            left: 60px;
        }

        .hero__image-wrapper.hero__image-wrapper--right {
            right: 60px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .hero__image-wrapper.hero__image-wrapper--left {
            left: 45px;
        }

        .hero__image-wrapper.hero__image-wrapper--right {
            right: 45px;
        }
    }

    @include media-breakpoint-up(fhd) {
        .hero__image-wrapper.hero__image-wrapper--left {
            left: 145px;
        }

        .hero__image-wrapper.hero__image-wrapper--right {
            right: 145px;
        }
    }
}
