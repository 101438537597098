.slider-wrapper {
    .slider-wrapper__images {
        border: 1px solid color(mercury);
        padding: var(--slider-wrapper-padding);
        position: relative;
    }

    .slider-wrapper__thumbnails {
        margin-top: 5px;
        position: relative;

        .card--gallery-item {
            opacity: .5;
            border: 1px solid color(mercury);
            padding: 5px;
            transition: all var(--speed-slow) var(--cubic-bezier);

            .card--gallery-item__inner {
                position: relative;
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    opacity: 1;
                }
            }
        }

        .tns-nav-active {
            .card--gallery-item {
                opacity: 1;
                border-color: color(breaker-bay);
            }
        }

        @include media-breakpoint-up(lg) {
            margin-top: 15px;
        }
    }
}

.arrows.arrows--slider {
    .arrow-left,
    .arrow-right {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 25px;
        min-height: 25px;
        margin: 0;
        z-index: 3;

        &[disabled] {
            opacity: .5;
        }
    }

    .arrow-left {
        @include center-y(left, 5px);
    }

    .arrow-right {
        @include center-y(right, 5px);
    }

    @include media-breakpoint-down(md) {
        .slider-wrapper__images & {
            .arrow-left {
                left: 15px;
            }

            .arrow-right {
                right: 15px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .arrow-left,
        .arrow-right {
            i {
                font-size: 15px;
            }
        }

        .arrow-left {
            left: calc(-1 * (var(--slider-wrapper-padding) + 30px));
            transform: translate(-100%, -50%);
        }

        .arrow-right {
            right: calc(-1 * (var(--slider-wrapper-padding) + 30px));
            transform: translate(100%, -50%);
        }
    }

    @include media-breakpoint-up(xxl) {
        .arrow-left,
        .arrow-right {
            i {
                font-size: 20px;
            }
        }

        .arrow-left {
            left: calc(-1 * (var(--slider-wrapper-padding) + 40px));
        }

        .arrow-right {
            right: calc(-1 * (var(--slider-wrapper-padding) + 40px));
        }

        .slider-wrapper__thumbnails & {
            .arrow-left,
            .arrow-right {
                i {
                    font-size: 15px;
                }
            }
        }
    }
}
