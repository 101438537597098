.modal {
    .store-locator {
        margin-top: 50px;

        .store-locator__search {
            text-align: left;
            position: relative;

            .store-locator__search-view {
                display: flex;

                .store-locator__search-view__cancel {
                        @include center-y('right', 20px);
                        width: 25px;
                        height: 25px;

                    &::before,
                    &::after {
                        @extend %icon;
                        display: block;
                        content: $icon-minus;
                        font-size: 5px;
                        color: color(metallic-bronze);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                    }

                        &::before {
                            transform: translate(-50%, -50%) rotate(-45deg);
                        }

                        &::after {
                            transform: translate(-50%, -50%) rotate(45deg);
                        }
                    }

                .form-group {
                    margin-bottom: 0;
                    position: relative;

                    .icon-search {
                        @include center-y('left', 15px);
                        font-size: 16px;
                    }

                    .form-control {
                        border: 1px solid color(alto);
                        padding: {
                            right: calc(var(--px-form-control) * 3);
                            left: 40px;
                        }

                        &::-webkit-search-decoration,
                        &::-webkit-search-cancel-button {
                            display: none;
                        }
                    }

                    @include media-breakpoint-up(xxl) {
                       .icon-search {
                            font-size: 18px;
                            left: 20px;
                        }

                        .form-control {
                            padding: {
                                left: 50px;
                            }
                        }
                    }
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }

                @include media-breakpoint-up(lg) {
                    opacity: 1;
                    width: 100%;
                    margin-top: calc((var(--size-input) / 2) * -1);
                    pointer-events: all;
                    transition: opacity var(--speed-default) linear, transform var(--speed-default) var(--cubic-bezier-2);
                }
            }
        }

        .store-locator__stores {
            margin-top: 20px;
            border: 1px solid rgba(color(metallic-bronze), .2);
            border-radius: 5px;
            padding: 10px 20px;

            .store-locator__store {
                overflow: hidden;
                border-bottom: 1px solid rgba(color(metallic-bronze), .3);
                position: relative;

                .store-locator__store-inner {
                    padding-right: 20px;
                    position: relative;
                }

                .store-locator__inner {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 20px 0;
                    line-height: 1;
                    color: color(metallic-bronze);
                    font: {
                        family: $font-family-secondary;
                        size: 16px;
                    };
                }

                .icon-marker-o-fill {
                    margin-right: 20px;
                }

                a {
                    display: block;
                    text-decoration: none;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }

            table {
                width: 100%;
                text-align: left;
            }
        }

        .no-results {
            margin-top: 20px;
        }

        @include media-breakpoint-up(xs) {
            .store-locator__stores {
                @include overflow(x);

                .store-locator__store {
                    .store-locator__inner {
                        .store-locator__data {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }
}
