.section.section--products {
    --pt-section: 45px;
    --pb-section: 75px;

    .section__body {
        text-align: center;
    }

    body.product-groups & {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

        .section__body {
            width: 100%;
        }
    }

    @include media-breakpoint-down(lg) {
        .section__body {
            width: 100%;
        }
    }

    @include media-breakpoint-up(lg) {
        --pt-section: 45px;
        --pb-section: 45px;
    }

    @include media-breakpoint-up(xl) {
        body.home-page & {
            --pt-section: 70px;
            --pb-section: 70px;
        }
    }
}

.section.section--products-overview {
    .product {
        margin-bottom: 40px;
    }
}

@include media-breakpoint-down(sm) {
    .home-page {
        .section--products {
            background-color: color(sea-buckthorn) !important; /* stylelint-disable-line declaration-no-important */

            .wave {
                path {
                    fill: color(sea-buckthorn) !important; /* stylelint-disable-line declaration-no-important */
                }
            }
        }
    }
}
