.accordion-item {
    border-bottom: 1px solid rgba(color(metallic-bronze), .3);
    background-color: transparent;
    position: relative;

    .accordion__term {
        display: flex;
        align-items: center;
        width: 100%;
        padding: var(--py-accordion) var(--pr-accordion) var(--py-accordion) var(--pl-accordion);
        font-family: $font-family-secondary;
        font-weight: 500;
        font-size: 1.8rem;
        position: relative;

        .accordion__icon {
            margin-top: 0;
            font-size: 4px;
            position: absolute;
            top: 22px;
            right: 0;
            transition: opacity var(--speed-default) ease;

            &.accordion__icon--collapsed {
                opacity: 1;
                margin: 0;
                transform: translateY(-50%);
            }

            &.accordion__icon--open {
                opacity: 1;
                font-size: 4px;
            }

            &.accordion__icon--vertical {
                transform: rotate(90deg);
                transition: transform var(--speed-default) ease;
            }
        }

        &.open {
            &::after {
                transform: scaleY(-1);
            }

            .accordion__icon--open {
                opacity: 1;
            }

            .accordion__icon--collapsed {
                transform: rotate(0);
            }
        }
    }

    .accordion__body {
        &.show {
            .accordion__description {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .accordion__description {
        opacity: 0;
        padding: 0 30px 10px 0;
        font-size: 1.8rem;
        transform: translateY(20px);
        transition: opacity var(--speed-fast) ease-out, transform var(--speed-default) var(--cubic-bezier-2);
    }

    mark {
        background: transparent;
        color: inherit;
        position: relative;

        span {
            display: inline-block;
            position: relative;

            svg {
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
                fill: color(milk);
            }
        }
    }

    + .accordion-item {
        margin-top: -1px;
    }

    &:not(:first-child):last-child {
        border-bottom: 0;
    }
}