.product-info {
	padding-top: 60px;

	.product-info__inner {
		padding: 50px 10px 100px 10px;
		background-color: color(milk);
		position: relative;
		z-index: 1;
	}

	.product-info__image-wrapper {
		position: relative;

		.product-info__text-balloon-container {
			display: block;
			max-width: 190px;
			height: 170px;
			position: relative;
			transform: translate(180px, -220px);
			z-index: 1;

			@include media-breakpoint-down(sm) {
				margin-top: -50px;
				margin-left: auto;
				right: -20px;
				transform: translate(0);
			}
		}
	}

	.product-info__bg-paw {
		display: unset;
		width: 300px;
		transform: rotate(20deg) translate(-30px, 60px);

		@include media-breakpoint-down(sm) {
			position: absolute;
			bottom: -20px;
			transform: rotate(60deg);
			transform-origin: top;
		}
	}

	.product-info__bg-text-balloon {
		display: unset;
		z-index: 2;
	}

	.product-info__text-balloon-wrapper {
		display: block;
		max-width: 170px;
		text-decoration: none;
		position: absolute;
		top: 40px;
		left: 20px;
		transform: rotate(8deg);
		z-index: 3;

		.product-info__text-balloon {
			opacity: 0;
			overflow: hidden;
			width: 140px;
			height: 90px;
			line-height: 2rem;
			color: color(milk);
			text-align: center;
			font: {
				family: $font-family-secondary;
				size: 1.6rem;
				weight: 500;
			}
			transform: rotate(5deg);
			z-index: 2;
			animation: text-fade-in .5s ease 1.5s forwards;
		}

		&.product-info__text-balloon-wrapper--spacing-right {
			right: -48px;
		}
	}

	.product-info__bg-fr {
		display: unset;
		max-width: 110px;
		position: absolute;
		top: 0;
		right: -17px;
		z-index: 2;
	}

	.product-info__images-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 15px 0 35px 0;

		.product-info__icon-wrapper {
			flex: 1 1 24%;
			margin-top: 20px;
			text-align: center;

			p {
				margin-top: 10px;
				font: {
					size: 2rem;
					weight: 900;
				}
				color: color(metallic-bronze);
			}

			@include media-breakpoint-down(sm) {
				max-width: 180px;
			}
		}

		.product-info__image {
			height: 50px;
		}

		.icon-plus {
			margin: 0 10px;
			font-size: 20px;
		}
	}

	.product-info__title {
		width: 100%;
		margin: 0;
		text-align: center;

		&.product-info__title--gutter {
			padding: 0 50px;
		}

		&.product-info__title--spacing-top {
			margin-top: 40px;
		}
	}

	.product-info__labels,
	.product-info__icons {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.product-info__labels {
		margin: 20px 0 10px 0;
	}

	.product-info__icons {
		margin-top: 20px;

		.product-info__icon-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			position: relative;

			.product-info__label {
				width: 100px;
				margin-top: 10px;
				margin-bottom: 20px;
			}

			.product-info__icon {
				width: auto;
				max-width: unset;
				height: 55px;
				margin-bottom: 15px;
			}

			.product-info__icon-title {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
			}

			p {
				margin-top: 10px;
				font: {
					size: 1.2rem;
					weight: 900;
				}
				line-height: 1;
				color: color(metallic-bronze);
			}
		}
	}

	.product-info__table {
		margin-top: 15px;

		tr {
			border-bottom: 1px solid rgba(color(metallic-bronze), .3);

			.product-info__table-head:nth-child(2),
			.product-info__table-head:nth-child(3) {
				text-decoration: underline;
			}

			.product-info__table-head:nth-child(2) {
				padding-right: 30px;
			}

			th,
			td {
				padding: 10px 0;

				&:first-child {
					width: 150px;
				}
			}

			td {
				&:nth-child(2) {
					padding-right: 55px;
				}
			}
		}
	}

	.product-info__small-text {
		font-size: 1.4rem;
	}

	.product-info__bg-fruit {
		display: none;
	}

	.product__info__bg-wrapper {
		display: flex;
		justify-content: center;
	}

	@keyframes slide-in {
		0% {
			transform: rotate(-20deg) translateX(-220px);
		}

		100% {
			transform: rotate(0deg) translateX(0);
		}
	}

	@include media-breakpoint-down(md) {
		.product-info__table {
			tr {
				.product-info__table-head:nth-child(2) {
					padding-right: 30px;
				}

				td {
					&:nth-child(2) {
						padding-right: 55px;
					}
				}
			}
		}

		.product-info__inner {
			&::before {
				width: calc(100% + 60px);
				background: color(milk);
				content: '';
				position: absolute;
				top: 0;
				right: -30px;
				bottom: 0;
				left: -30px;
				z-index: -1;
			}
		}
	}

	@include media-breakpoint-only(sm) {
		.product-info__table {
			tr {
				th:first-child {
					width: 340px !important; /* stylelint-disable-line declaration-no-important */
				}
			}
		}

		.product-info__image-wrapper {
			width: 430px;
		}

		.product-info__bg-text-balloon {
			top: -90px;
			right: 90px;
		}
	}

	@include media-breakpoint-up(sm) {
		.product-info__images-wrapper,
		.product-info__labels,
		.product-info__icons {
			margin-right: auto;
			margin-left: auto;
		}

		.product-info__table {
			width: 100%;

			tr th:first-child {
				width: auto;
			}
		}

		.product-info__image-wrapper {
			.product-info__text-balloon-container {
				position: absolute;
				transform: translate(240px, -290px);
			}
		}

		.product-info__bg-paw {
			width: 310px;
			transform: rotate(30deg) translate(-10px, -40px);
		}
	}

	@include media-breakpoint-up(md) {
		margin: 0 auto;
		position: relative;

		.product-info__table {
			tr {
				th:first-child {
					width: 280px !important; /* stylelint-disable-line declaration-no-important */
				}
			}
		}

		.product-info__icons {
			.product-info__icon-wrapper {
				p {
					font-size: 1.6rem;
				}
			}
		}

		.product-info__inner {
			padding: 60px 70px 100px 70px;
			background-image: url('~/../../images/content/wobble2.svg');
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			background-color: transparent;
			position: relative;
			z-index: 1;

			.product-info__title {
				html.contrast-mode-avg & {
					color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
				}

				html.contrast-mode-high & {
					color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
				}

				html.contrast-mode-max & {
					color: color(cola) !important; /* stylelint-disable-line declaration-no-important */
				}
			}

			html.contrast-mode-avg & {
				color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
			}

			html.contrast-mode-high & {
				color: color(metallic-bronze) !important; /* stylelint-disable-line declaration-no-important */
			}

			html.contrast-mode-max & {
				color: color(cola) !important; /* stylelint-disable-line declaration-no-important */
			}
		}

		.product-info__bg-fruit {
			display: unset;
			width: 133px;
			position: absolute;
			bottom: 130px;
			left: -46px;
			z-index: 2;
		}

		.product-info__bg-paw {
			margin-left: 30px;
		}

		.product-info__bg-text-balloon {
			right: 150px;
			z-index: 2;
		}
	}

	@include media-breakpoint-up(lg) {
		.product-info__inner {
			padding: 90px 55px 70px 55px;
			background-size: cover;
			background-position: unset;
		}

		.product-info__title {
			&.product-info__title--spacing-top {
				margin-top: 80px;
			}
		}

		.product-info__table {
			tr {
				th:first-child {
					width: 260px !important; /* stylelint-disable-line declaration-no-important */
				}

				.product-info__table-cell {
					&.product-info__table-cell--bold {
						font-weight: 700;
					}

					&.product-info__table-cell--italic {
						font-style: italic;
					}

					&.product-info__table-cell--offset {
						padding-left: 15px;
					}
				}

				.product-info__table-cell:nth-child(2) {
					padding-right: 118px;
				}

				.product-info__table-cell:nth-child(3) {
					width: 90px;
				}
			}
		}

		.product-info__image-wrapper {
			position: absolute;
			right: -160px;
			bottom: 180px;

			.product-info__text-balloon-container {
				position: relative;
				transform: translate(240px, -370px);
			}

			a {
				display: block;
			}
		}

		.product-info__bg-paw {
			width: 370px;

			&.animation--active {
				animation: slide-in cubic-bezier(.5, .03, .16, 1) 1s 1 forwards;
			}
		}

		.product-info__bg-fruit {
			display: unset;
			width: 190px;
			position: absolute;
			bottom: -9px;
			left: -96px;
			z-index: 2;
		}

		.product-info__text-balloon-wrapper {
			opacity: 0;
			display: block;
			z-index: 3;
			animation: text-fade-in .5s ease 1.5s forwards;

			.product-info__text-balloon {
				opacity: 0;
				line-height: 2rem;
				color: color(milk);
				text-align: center;
				transform: rotate(-28deg);
				font: {
					family: $font-family-secondary;
					size: 1.6rem;
					weight: 500;
				}
				animation: text-fade-in .5s ease 1.5s forwards;
			}
		}

		.product-info__bg-text-balloon {
			transform-origin: center;
			transform-box: fill-box;
			z-index: 2;

			&.animation--active {
				animation: rotate-in cubic-bezier(.5, .03, .16, 1) 1s .3s 1 forwards, fade-in cubic-bezier(.5, .03, .16, 1) 1s .3s 1 forwards;
			}
		}

		.product-info__icons {
			.product-info__icon-wrapper {
				.product-info__image {
					height: 81px;
				}

				.product-info__icon {
					height: 74px;
				}

				.product-info__label {
					width: 100%;
					height: 42px;
				}

				.icon-plus {
					font-size: 2.4rem;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		padding-bottom: 60px;

		.product-info__table {
			tr {
				th:first-child {
					width: 270px !important; /* stylelint-disable-line declaration-no-important */
				}
			}
		}

		.product-info__inner {
			background-size: cover;
			background-position: unset;
		}

		.product-info__bg-fr {
			max-width: 200px;
			top: 10px;
			right: -100px;

			.product-fruit-splits & {
				top: -40px;
				right: -50px;
			}
		}

		.product-info__image-wrapper {
			right: -220px;

			.product-info__text-balloon-container {
				transform: translate(300px, -350px);
			}
		}

		.product-info__bg-paw {
			width: 400px;
		}

		.product-info__text-balloon-wrapper {
			opacity: 0;
			animation: text-fade-in .5s ease 2.5s forwards;
		}

		.product-info__bg-text-balloon {
			opacity: 0;
			animation: text-fade-in .5s ease 2.5s forwards;
		}
	}

	@include media-breakpoint-up(xxl) {
		.product-info__table {
			tr {
				th:first-child {
					width: 340px !important; /* stylelint-disable-line declaration-no-important */
				}
			}
		}

		.product-info__inner {
			padding: 90px 80px 90px 80px;
		}

		.product-info__icons {
			.product-info__icon-wrapper {
				p {
					font-size: 2rem;
				}
			}
		}

		.product-info__image-wrapper {
			.product-info__text-balloon-container {
				max-width: 250px;
				transform: translate(300px, -400px);
			}
		}

		.product-info__text-balloon-wrapper {
			left: 10px;

			.product-info__text-balloon {
				width: 200px;
				height: 110px;
				font: {
					size: 20px;
				}
				line-height: 25px;
			}
		}

		.product-info__bg-text-balloon {
			width: 230px;
		}

		.product-info__bg-paw {
			margin-top: -70px;
		}
	}

	@include media-breakpoint-up(fhd) {
		.product-info__table {
			tr {
				th:first-child {
					width: 400px !important; /* stylelint-disable-line declaration-no-important */
				}
			}
		}

		.product-info__inner {
			padding: 100px 120px 85px 120px;
		}
	}
}
