:root {
    // Size
    --size-custom-control-indicator: 20px;
    --size-input: 55px;
    --size-button-circle: 40px;
    --step-image-size: 210px;
    --size-spring-spinger: 40px;
    --size-accordion-indicator: 14px;

    // Width
    --width-grid-gutter: 15px;
    --width-wave: 1922px;
    --width-filter-button: 140px;

    // Height
    --height-btn: 45px;
    --height-wave: 30px;
    --height-parallax-images: 95px;
    --height-navbar: 85px;

    // Margin
    --step-image-margin: 40px;
    --mb-section-title: 15px;
    --margin-parallax-image: 10px;

    // Padding
    --pt-section: 70px;
    --pb-section: 70px;
    --padding-section: 70px;
    --px-form-control: 15px;
    --py-accordion: 10px;
    --pr-accordion: 30px;
    --pl-accordion: 0;

    // Font size
    --font-size-base: 1.6rem;
    --font-size-base-px: 16px;
    --font-size-input: 1.8rem;
    --font-size-section-title: 3rem;
    --font-size-html: 10px;
    --font-size-multiplier: 1;

    // Line height
    --line-height-base: 2.6rem;
    --line-height-base-px: 26px;
    --line-height-section-title: 33px;

    // Transition
    --speed-fast: .125s;
    --speed-default: .25s;
    --speed-slow: .5s;
    --speed-sluggish: 1s;
    --speed-snail: 2s;
    --cubic-bezier: cubic-bezier(.05, .69, .14, 1);
    --cubic-bezier-2: cubic-bezier(.215, .61, .355, 1);
    --cubic-bezier-3: cubic-bezier(.3, .01, 0, .98);
    --cubic-bezier-4: cubic-bezier(.1, .9, 0, 1.2);

    @include media-breakpoint-up(lg) {
        // Size
        --step-image-size: 260px;

        // Height
        --height-parallax-images: 135px;

        // Width
        --width-grid-gutter: 20px;
        --width-filter-button: 170px;

        // Margin
        --mb-section-title: 20px;

        // Padding
        --pt-section: 90px;
        --pb-section: 110px;
        --px-form-control: 25px;

        // Font size
        --font-size-base: 1.8rem;
        --font-size-base-px: 18px;
        --font-size-section-title: 50px;

        // Line height
        --line-height-base: 3.2rem;
        --line-height-base-px: 32px;
        --line-height-section-title: 1;
    }

    @include media-breakpoint-up(xl) {
        // Size
        --size-button-circle: 45px;

        // Height
        --height-btn: 50px;
        --height-wave: 40px;
        --height-parallax-images: 165px;
        --height-navbar: 100px;

        // Font size
        --font-size-section-title: 60px;

        // Margin
        --mb-section-title: 25px;
        --margin-parallax-image: 60px;

        // Padding
        --pt-section: 130px;
    }

    @include media-breakpoint-up(xxl) {
        // Size
        --step-image-size: 285px;

        // Font size
        --font-size-base: 2rem;
        --font-size-base-px: 20px;

        // Width
        --width-grid-gutter: 25px;
        --width-filter-button: 220px;

        // Margin
        --mb-section-title: 30px;
        --margin-parallax-image: 45px;

        // Padding
        --pt-section: 160px;
        --pb-section: 140px;

        // Line height
        --line-height-base: 3.4rem;
        --line-height-base-px: 34px;
    }

    @include media-breakpoint-up(fhd) {
        // Height
        --height-parallax-images: 245px;

        // Width
        --width-grid-gutter: 30px;

        // Margin
        --margin-parallax-image: 145px;
    }
}

// Fonts
$font-path: '../fonts';
$font-family-base: 'Avenir', sans-serif;
$font-family-secondary: 'Mr_dodo', sans-serif;
$font-family-tertiary: 'MineruCom-Bold', sans-serif;
