.paginate-links {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .previous {
        margin-right: 15px;
    }

    .next {
        margin-left: 15px;
    }

    .previous,
    .next,
    .number {
        text-decoration: none;

        @media (hover: hover) and (pointer: fine) {
            transition: color var(--speed-fast) ease-out;

            &:hover {
                color: color(metallic-bronze);
            }
        }
    }

    .number,
    .ellipsis {
        padding: 0 10px;
        font: {
            family: $font-family-secondary;
            size: 2rem;
            weight: 500;
        }
        color: color(metallic-bronze);
        position: relative;

        i.icon-minus {
            opacity: 0;
            display: none;
            transition: opacity var(--speed-default) ease;
        }

        &:nth-child(2) {
            margin-left: auto;
        }

        &:nth-last-child(2) {
            margin-right: auto;
        }

        &.active {
            font-weight: 700;
            color: color(metallic-bronze);

            i.icon-minus {
                @include center-x(bottom, 0);
                opacity: 1;
                display: block;
                font-size: 4px;
                position: absolute;
            }
        }
    }

    .btn {
        margin-top: 0;
        font-size: 3rem;
        color: color(metallic-bronze);
    }

    .disabled {
        opacity: .3;
        pointer-events: none;
    }

    &.paginate-links--spacing-top {
        margin-top: 70px;
    }
}
