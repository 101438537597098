.emergency-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 10px;
    }

    p,
    ul,
    ol,
    table,
    blockquote {
        + h1,
        + h2,
        + h3,
        + h4,
        + h5,
        + h6 {
            margin-top: 25px;
        }
    }
}